import {UUID} from "@/core/types/ai-types"
import store from "@/store"
import {AiWell} from "@/core/types/ai-interfaces"
import {Nullable} from "@/types/common"
const splitter = '___'

export type ChannelUUID = `${UUID}${typeof splitter}${UUID}`

export const channelUuid = (wellId: UUID, channelId: UUID): ChannelUUID => {
    const wellKey = store.getters.getSelectedWellId === wellId ? 'current_well' : wellId
    return `${wellKey}${splitter}${channelId}`
}

export function getLocale(well?: Nullable<Partial<AiWell>>) {
    const wellLocale = _.get(well, 'locale', {})
    const userLocale = store.getters.getUserLocale || {}
    return _.isEmpty(wellLocale) ? userLocale : wellLocale
}
