import Crud from '@/store/modules/crud.js'

const URL = 'alerts/subscriptions'
const subscriptions = new Crud(URL)

const actions = subscriptions.actions()

subscriptions.actions = () => ({
    ...actions,
    getFilterData: ({commit}) => {
        return subscriptions._apiCall({commit}, {url: 'alerts/subscriptions/filters', skipCommit: true})
    },
    CLONE: ({commit}, {id}) => {
        return subscriptions._apiCall({commit}, {method: 'POST', url: `alerts/subscriptions/${id}/clone`, skipCommit: true})
    },
    SHARE: ({commit}, {id, data}) => {
        return subscriptions._apiCall({commit}, {method: 'POST', url: `alerts/subscriptions/${id}/share_copy`, data, skipCommit: true})
    },
})

export default subscriptions.module()
