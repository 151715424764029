import axios from './axios'
import {AUTH_LOGOUT} from "@/store/actions/auth"
import {simpleErrorNotification, simpleSuccessNotification} from "@/utils/helpers"
import _ from "lodash"
import {AxiosError, AxiosRequestConfig, AxiosResponseHeaders} from "axios"
import {Commit} from "vuex"
import StaticAxios from 'axios'
import RequestManager from '@/plugins/RequestManager'
import store from '@/store'
import {SET_LAST_UPDATED} from '@/store/actions/params'
import {UUID} from '@/core/types/ai-types'
import {DataResponse} from '@/core/types/api'

export interface IRequestConfig extends Partial<AxiosRequestConfig> {
    uninterrupted?: boolean,
    needSuccessNotification?: boolean
    requestId?: string
    sessionId?: UUID
    backendConfirm? : {
        component?: string
        requestHandler?: () => Promise<unknown>
    }
}

export interface IApiCallResponse extends DataResponse<any>{
    status: number
    message: string
    data: any
    headers: AxiosResponseHeaders | Partial<Record<string, string>>
    request: XMLHttpRequest
}

export interface ErrorData {
    message: unknown
    data?: {
        email: unknown
        token: unknown
    }
}

export const apiCall = <ResponseType extends DataResponse = IApiCallResponse>(requestConfig: IRequestConfig) => new Promise<ResponseType>((resolve, reject) => {
    const {
        url,
        method,
        data,
        params,
        responseType,
        onUploadProgress,
        sessionId,
        headers: originalHeaders,
        backendConfirm,
    } = requestConfig
    const controller = new AbortController()
    const headers = sessionId ?
        {
            ...originalHeaders,
            'View-Session-Id': sessionId
        } :
        originalHeaders
    const axiosRequest = axios<null, ResponseType>({
        method: method,
        url: url,
        data: data,
        params: params,
        responseType,
        headers,
        onUploadProgress,
        signal: controller.signal,
    }).then((response: ResponseType) => {
        store.commit(SET_LAST_UPDATED)
        resolve({
            ...response,
            status: response.status,
            message: response.statusText,
            data: response.data,
            headers: response.headers as AxiosResponseHeaders,
            request: response.request,
        })
    }).catch(async (error: AxiosError<ErrorData>) => {
        //connection lost handling
        if (!error) {
            //@ts-ignore
            error = {}
        }
        //connection lost handling
        if (!error.response) {
            if (error.code === AxiosError.ERR_NETWORK) {
                //@ts-ignore
                error.response = {status: 503, message: error, data: {message: 'Could not connect to the Server'}}
            } else {
                //@ts-ignore
                error.response = {status: 503, message: error, data: {message: error}}
            }
        }

        const rejectError = async (error: AxiosError) => {
            const err = {
                status: _.get(error, 'response.status', ''),
                message: _.get(error, 'response.data.message') || _.get(error, 'message', 'Unknown error!'),
                data: _.get(error, 'response.data', []),
            }
            if (responseType === 'blob') {
                //@ts-ignore
                err.message = err.data?.text ? JSON.parse(await err.data.text()).message : err.message
            }
            reject(err)
        }
        switch (error.response?.status) {
            case 401:
                store.commit(AUTH_LOGOUT)
                rejectError(error)
                break
            case 406:
                window.app.$eventHub.$emit('notification', {
                    text: error.response.data.message,
                    options: {
                        onClose: () => {
                            window.app.$router.push({
                                name: 'password-change',
                                query: {
                                    email: error.response?.data?.data?.email,
                                    token: error.response?.data?.data?.token,
                                },
                            })
                        },
                        type: 'warning',
                        timeout: 1500,
                    }
                })
                localStorage.removeItem('params')
                sessionStorage.removeItem('params')
                reject({})
                break
            case 409:
                window.app.$eventHub.$emit('notification', {
                    text: error.response.data.message,
                    options: {
                        onClose: () => {
                            //@ts-ignore
                            window.location.reload(true)
                        },
                        type: 'warning',
                    }
                })
                localStorage.removeItem('params')
                sessionStorage.removeItem('params')
                break
            case 449:
                window.app.$eventHub.$emit('backend-needs-confirmation', {
                    request: _.pick(error.config, ['url', 'method', 'data', 'params']),
                    response: {data: error.response.data, component: backendConfirm?.component},
                    requestHandler: backendConfirm?.requestHandler,
                    resolveHandler: resolve,
                    rejectHandler: reject,
                })
                break
            default:
                rejectError(error)
                break
        }
    })
    RequestManager.addRequest({axiosRequest, controller, requestConfig})
})

export function _apiCall({commit}: { commit: Commit }, params: IRequestConfig) {
    return new Promise((resolve, reject) => {
        apiCall(params)
            .then((resp: any) => {
                if (params.needSuccessNotification) {
                    simpleSuccessNotification(resp.data.message)
                }
                resolve(resp)
            })
            .catch(err => {
                hasError({commit}, err)
                reject(err)
            })
    })
}

export function hasError({commit}: { commit: Commit }, err: any) {
    if ([401].indexOf(err.status) > -1) { // if err is unauthorized then logout
        commit('ERROR', err)
        commit(AUTH_LOGOUT)
        // } else if ([403].indexOf(err.status) > -1) { // if err is forbidden then go to 403
        //     window.app.$router.push({ name: '403' });
    } else {
        if (err.data.message instanceof StaticAxios.Cancel && !err.data.message.message) return
        //prevent notification if request was canceled
        if (commit) {
            commit('ERROR', err)
        }
        simpleErrorNotification(err.message)
    }
}

export default {
    apiCall,
}
