import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import {PERMISSIONS, SAVED_SEARCH_KEYS, FILE_DRIVE_FOLDER_ID} from "@/assets/enums.ts"
import {Config} from '@/utils/config'

Vue.use(Router)

const mainTitle = Config.VUE_APP_TITLE
export const defaultRouteName = 'drilling-dashboards'
const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            // redirect: {name: defaultRouteName},
            component: () => import('../views/layouts/RootLayout.vue'),
            children: [
                {
                    path: 'public',
                    component: () => import('../views/layouts/PublicLayout.vue'),
                    children: [
                        {
                            path: 'sign_in',
                            name: 'sign-in',
                            component: () => import(/* webpackChunkName: "sign-in" */ '../components/auth/SignIn'),
                            meta: {
                                title: 'Sign In',
                            },
                        },
                        {
                            path: 'sso',
                            name: 'sso',
                            component: () => import(/* webpackChunkName: "sso" */ '../components/auth/SSO'),
                            meta: {
                                title: 'Single Sign-On',
                            },
                        },
                        {
                            path: 'password/forgot',
                            name: 'password-forgot',
                            component: () => import(/* webpackChunkName: "password-forgot" */ '../components/auth/ForgotPassword'),
                            meta: {
                                title: 'Forgot password?',
                            },
                        },
                        {
                            path: 'password/reset',
                            name: 'password-change',
                            component: () => import(/* webpackChunkName: "password-change" */ '../components/auth/ChangePassword'),
                            meta: {
                                title: 'Change password',
                            },
                            beforeEnter(to, from, next) {
                                if (Object.keys(to.query).some(prop => ~prop.indexOf('token'))) { // if has not token in params then go to sign in
                                    next()
                                } else {
                                    next({
                                        name: 'sign-in',
                                    })
                                }
                            }
                        },
                        {
                            path: '404',
                            name: '404',
                            component: () => import(/* webpackChunkName: "404" */ '@/views/errors/404'),
                            meta: {
                                title: 'Error 404',
                            },
                        },
                        {
                            path: '403',
                            name: '403',
                            component: () => import(/* webpackChunkName: "403" */ '@/views/errors/403'),
                            meta: {
                                title: 'Error 403',
                            },
                        },
                    ],
                },
                {
                    path: '',
                    // redirect: {name: defaultRouteName},
                    meta: {
                        title: mainTitle,
                        authRequired: true,
                    },
                    component: () => import('../views/layouts/AuthLayout.vue'),
                    children: [
                        // *** BEGIN Redirection block BEGIN ***
                        {
                            path: 'dashboards/:well_uid?', // todo delete it!
                            redirect: {name: defaultRouteName}
                        },
                        {
                            path: 'equipment/well/:well_uid?', // todo delete it!
                            redirect: {name: 'equipment'},
                            children: [
                                {
                                    path: 'bha', // todo delete it!
                                    redirect: {name: 'bha-summary'}
                                },
                                {
                                    path: 'bha/:assembly_id', // todo delete it!
                                    redirect: {name: 'bha-details'}
                                },
                                {
                                    path: 'bha/:assembly_id', // todo delete it!
                                    redirect: {name: 'bha-details'}
                                },
                                {
                                    path: 'casing', // todo delete it!
                                    redirect: {name: 'casing-summary'}
                                },
                                {
                                    path: 'casing/:assembly_id', // todo delete it!
                                    redirect: {name: 'casing-details'}
                                },
                                {
                                    path: 'mud_reports', // todo delete it!
                                    redirect: {name: 'mud_reports'}
                                },
                                {
                                    path: 'brs', // todo delete it!
                                    redirect: {name: 'bit-run-summary'}
                                },
                                {
                                    path: 'shipment', // todo delete it!
                                    redirect: {name: 'shipment-summary'}
                                },
                                {
                                    path: 'shipment/:shipment_id', // todo delete it!
                                    redirect: {name: 'shipment-details'}
                                },
                                {
                                    path: 'tool_catalog/:tool_type?/:component_id?', // todo delete it!
                                    redirect: {name: 'tool-catalog'}
                                },
                                {
                                    path: 'rig_inventory/:tool_type?/:component_id?', // todo delete it!
                                    redirect: {name: 'rig-inventory'}
                                },
                            ]
                        },
                        {
                            path: 'engineering/well/:well_uid?', // todo delete it!
                            redirect: {name: 'engineering'}
                        },
                        {
                            path: 'data_insights', // todo delete it!
                            children: [
                                {
                                    path: 'well_formations/:well_uid?', // todo delete it!
                                    redirect: {name: 'well-formations'}
                                },
                                {
                                    path: 'depth_analysis_log/:well_uid?', // todo delete it!
                                    redirect: {name: 'depth_analysis_log'}
                                },
                                {
                                    path: 'time_analysis_log/:well_uid?', // todo delete it!
                                    redirect: {name: 'time_analysis_log'}
                                },
                                {
                                    path: 'custom_log/:well_uid?', // todo delete it!
                                    redirect: {name: 'custom_log'}
                                },
                                {
                                    path: 'torque_and_drag/:well_uid?', // todo delete it!
                                    redirect: {name: 'field_engineering'}
                                },
                                {
                                    path: 'scatter_plot/:well_uid?', // todo delete it!
                                    redirect: {name: 'scatter_plot'}
                                },
                                {
                                    path: 'scatter_plot/:well_uid?', // todo delete it!
                                    redirect: {name: 'scatter_plot'}
                                },
                                {
                                    path: ':well_uid?/roadmap', // todo delete it!
                                    redirect: {name: 'roadmap'}
                                },
                                {
                                    path: ':well_uid?/roadmap/:roadmap_id?/interval/:interval_id?', // todo delete it!
                                    redirect: {name: 'roadmap-configuration'}
                                },
                            ],
                        },
                        {
                            path: 'reports', // todo delete it!
                            children: [
                                {
                                    path: 'activities/:view/:well_uid?', // todo delete it!
                                    redirect: {name: 'activities'}
                                },
                                {
                                    path: 'connections/:view/:well_uid?', // todo delete it!
                                    redirect: {name: 'connections'}
                                },
                                {
                                    path: 'days_vs_depth/:well_uid?', // todo delete it!
                                    redirect: {name: 'days_vs_depth'}
                                },
                                {
                                    path: 'drilling_stat/:well_uid?', // todo delete it!
                                    redirect: {name: 'drilling_stat'}
                                },
                                {
                                    path: 'rotary_analysis/:well_uid?', // todo delete it!
                                    redirect: {name: 'rotary_analysis'}
                                },
                                {
                                    path: 'slide_analysis/:well_uid?', // todo delete it!
                                    redirect: {name: 'slide_analysis'}
                                },
                                {
                                    path: 'slidesheet/:well_uid?', // todo delete it!
                                    redirect: {name: 'slidesheet'}
                                },
                                {
                                    path: 'time_log/:well_uid?', // todo delete it!
                                    redirect: {name: 'time_log'}
                                },
                                {
                                    path: 'zero_statistics/:well_uid?', // todo delete it!
                                    redirect: {name: 'zero_statistics'}
                                },
                                {
                                    path: 'tripping_stat/:well_uid?', // todo delete it!
                                    redirect: {name: 'tripping_stat'}
                                },
                                {
                                    path: 'tripping_analysis/:view/:well_uid?', // todo delete it!
                                    redirect: {name: 'tripping_analysis'}
                                },
                            ],
                        },
                        {
                            path: 'directional_data', // todo delete it!
                            children: [
                                {
                                    path: 'survey/:well_uid?', // todo delete it!
                                    redirect: {name: 'survey'}
                                },
                                {
                                    path: 'plans/:well_uid?', // todo delete it!
                                    redirect: {name: 'plans'}
                                },
                                {
                                    path: 'planned_trajectories/:well_uid?', // todo delete it!
                                    redirect: {name: 'planned_trajectories'}
                                },
                                {
                                    path: 'plan_vs_actual/:view/:well_uid?', // todo delete it!
                                    redirect: {name: 'plan_vs_actual'}
                                },
                                {
                                    path: 'geosteering/:well_uid?', // todo delete it!
                                    redirect: {name: 'geosteering'}
                                },
                                {
                                    path: 'navigation/:view/:table/:well_uid?', // todo delete it!
                                    redirect: {name: 'navigation'}
                                },
                                {
                                    path: 'deviation/:well_uid?', // todo delete it!
                                    redirect: {name: 'deviation'}
                                },
                                {
                                    path: 'buildwalk/:well_uid?', // todo delete it!
                                    redirect: {name: 'buildwalk'}
                                },
                                {
                                    path: 'separation/:well_uid?', // todo delete it!
                                    redirect: {name: 'separation'}
                                },
                                {
                                    path: 'ac_report/:view/:well_uid?', // todo delete it!
                                    redirect: {name: 'ac_report'}
                                },
                                {
                                    path: 'location/:well_uid?', // todo delete it!
                                    redirect: {name: 'well_location'}
                                },
                            ]
                        },
                        {
                            path: 'edm', // todo delete it!
                            children: [
                                {
                                    path: 'well/:well_uid?', // todo delete it!
                                    redirect: {name: 'well-edm'}
                                },
                                {
                                    path: 'project/:well_uid?', // todo delete it!
                                    redirect: {name: 'project-edm'}
                                },
                            ],
                        },
                        {
                            path: 'file_drive/:well_uid?', // todo delete it!
                            redirect: {name: 'file_drive'}
                        },
                        {
                            path: 'management', // todo delete it!
                            children: [
                                {
                                    path: 'control/:well_uid?', // todo delete it!
                                    redirect: {name: 'tools-control'}
                                },
                                {
                                    path: 'well_configuration/:well_uid?', // todo delete it!
                                    redirect: {name: 'well-configuration'}
                                },
                            ],
                        },
                        {
                            path: 'multiwell', // todo delete it!
                            children: [
                                {
                                    path: 'dashboards', // todo delete it!
                                    redirect: {name: 'multiwell-drilling_dashboards'},
                                },
                            ],
                        },
                        // *** END Redirection block END ***
                        {
                            path: '',
                            component: () => import('../views/layouts/SingleWellLayout.vue'),
                            meta: {
                                authRequired: true,
                            },
                            children: [
                                {
                                    path: 'drilling',
                                    redirect: {name: defaultRouteName},
                                    meta: {
                                        title: mainTitle,
                                        authRequired: true,
                                    },
                                    component: () => import('../views/layouts/DrillingLayout.vue'),
                                    children: [
                                        {
                                            path: 'dashboards/:well_uid?',
                                            name: defaultRouteName,
                                            meta: {
                                                title: 'Dashboards',
                                                authRequired: true,
                                            },
                                            component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard'),
                                        },
                                        {
                                            path: 'equipment/well/:well_uid?',
                                            name: 'equipment',
                                            meta: {
                                                title: 'Equipment',
                                                authRequired: true,
                                                hideBreadcrumbsInMobile: true,
                                            },
                                            component: () => import('../views/layouts/pages/PageWithBreadcrumbs.vue'),
                                            children: [
                                                {
                                                    path: 'bha',
                                                    name: 'bha-summary',
                                                    meta: {
                                                        title: 'BHA - Summary',
                                                        breadcrumbs: [
                                                            {text: 'Equipment', disabled: true},
                                                            {
                                                                text: 'BHA Summary',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/equipment/bha_summary'),
                                                },
                                                {
                                                    path: 'bha/:assembly_id',
                                                    name: 'bha-details',
                                                    meta: {
                                                        title: 'BHA - Details',
                                                        breadcrumbs: (payload) => {
                                                            return [
                                                                {text: 'Equipment', disabled: true},
                                                                {
                                                                    text: 'BHA Summary',
                                                                    disabled: false,
                                                                    to: {name: 'bha-summary', params: {well_uid: payload.params.well_uid}},
                                                                    exact: true
                                                                },
                                                            ]
                                                        },
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/equipment/bha_details'),
                                                },
                                                {
                                                    path: 'casing',
                                                    name: 'casing-summary',
                                                    meta: {
                                                        title: 'Casing - Summary',
                                                        breadcrumbs: [
                                                            {text: 'Equipment', disabled: true},
                                                            {
                                                                text: 'Casing Summary',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/equipment/casing_summary'),
                                                },
                                                {
                                                    path: 'casing/:assembly_id',
                                                    name: 'casing-details',
                                                    meta: {
                                                        title: 'Casing - Details',
                                                        breadcrumbs: (payload) => {
                                                            return [
                                                                {text: 'Equipment', disabled: true},
                                                                {
                                                                    text: 'Casing Summary',
                                                                    disabled: false,
                                                                    to: {name: 'casing-summary', params: {well_uid: payload.params.well_uid}},
                                                                    exact: true
                                                                },
                                                            ]
                                                        },
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/equipment/casing_details'),
                                                },
                                                {
                                                    path: 'mud_reports',
                                                    name: 'mud_reports',
                                                    meta: {
                                                        title: 'Mud Reports',
                                                        breadcrumbs: [
                                                            {text: 'Equipment', disabled: true},
                                                            {
                                                                text: 'Mud Summary',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "mud_reports" */ '../views/equipment/mud_reports'),
                                                },
                                                {
                                                    path: 'mud_program',
                                                    name: 'mud_program',
                                                    meta: {
                                                        title: 'Mud Program',
                                                        breadcrumbs: [
                                                            {text: 'Equipment', disabled: true},
                                                            {
                                                                text: 'Mud Program',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "mud_reports" */ '../views/equipment/mud_program'),
                                                },
                                                {
                                                    path: 'brs',
                                                    name: 'bit-run-summary',
                                                    meta: {
                                                        title: 'Bit Run - Summary',
                                                        breadcrumbs: [
                                                            {text: 'Equipment', disabled: true},
                                                            {
                                                                text: 'Well Run Sheet',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/equipment/brs_summary'),
                                                },
                                                {
                                                    path: 'shipment',
                                                    name: 'shipment-summary',
                                                    meta: {
                                                        title: 'Shipments - Summary',
                                                        breadcrumbs: [
                                                            {text: 'Equipment', disabled: true},
                                                            {
                                                                text: 'Shipment Summary',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/equipment/shipment_summary'),
                                                },
                                                {
                                                    path: 'shipment/:shipment_id',
                                                    name: 'shipment-details',
                                                    meta: {
                                                        title: 'Shipment - Details',
                                                        breadcrumbs: (payload) => {
                                                            return [
                                                                {text: 'Equipment', disabled: true},
                                                                {
                                                                    text: 'Shipment Summary',
                                                                    disabled: false,
                                                                    to: {name: 'shipment-summary', params: {well_uid: payload.params.well_uid}},
                                                                    exact: true
                                                                },
                                                            ]
                                                        },
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/equipment/shipment_details'),
                                                },
                                                {
                                                    path: 'tool_catalog/:tool_type?/:component_id?',
                                                    name: 'tool-catalog',
                                                    meta: {
                                                        title: 'Tool Catalog',
                                                        breadcrumbs: [
                                                            {text: 'Equipment', disabled: true},
                                                            {text: 'Tool Catalog', disabled: false},
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "tool_catalog" */ '../views/equipment/tool_catalog'),
                                                },
                                                {
                                                    path: 'rig_inventory/:tool_type?/:component_id?',
                                                    name: 'rig-inventory',
                                                    meta: {
                                                        title: 'Rig Inventory',
                                                        breadcrumbs: [
                                                            {text: 'Equipment', disabled: true},
                                                            {text: 'Rig Inventory', disabled: false},
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "rig_inventory" */ '../views/equipment/rig_inventory'),
                                                },
                                                {
                                                    path: 'tickets',
                                                    name: 'tickets-summary',
                                                    meta: {
                                                        title: 'Field Tickets',
                                                        breadcrumbs: [
                                                            {text: 'Equipment', disabled: true},
                                                            {text: 'Field Tickets', disabled: false},
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "tickets" */ '../views/tickets'),
                                                },
                                                {
                                                    path: 'tickets/:ticket_id',
                                                    name: 'tickets-details',
                                                    meta: {
                                                        title: 'Ticket',
                                                        breadcrumbs: (payload) => {
                                                            return [
                                                                {text: 'Equipment', disabled: true},
                                                                {
                                                                    text: 'Field Tickets',
                                                                    disabled: false,
                                                                    to: {name: 'tickets-summary', params: {well_uid: payload.params.well_uid}},
                                                                    exact: true
                                                                },
                                                            ]
                                                        },
                                                    },
                                                    component: () => import(/* webpackChunkName: "tickets" */ '../views/tickets/ticket'),
                                                },
                                            ]
                                        },
                                        {
                                            path: 'engineering/well/:well_uid?',
                                            name: 'engineering',
                                            meta: {
                                                title: 'Engineering',
                                                authRequired: true,
                                                hideBreadcrumbsInMobile: true,
                                            },
                                            component: () => import('../views/layouts/pages/PageWithBreadcrumbs.vue'),
                                            children: [
                                                {
                                                    path: 'design',
                                                    name: 'design-summary',
                                                    meta: {
                                                        title: 'Design Summary',
                                                        breadcrumbs: [
                                                            {text: 'Engineering', disabled: true},
                                                            {
                                                                text: 'Design Summary',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/engineering/design/summary'),
                                                },
                                                {
                                                    path: 'design/:design_id',
                                                    name: 'design-details',
                                                    meta: {
                                                        title: 'Wellbore Design',
                                                        breadcrumbs: (payload) => {
                                                            return [
                                                                {text: 'Engineering', disabled: true},
                                                                {
                                                                    text: 'Design Summary',
                                                                    disabled: false,
                                                                    to: {name: 'design-summary', params: {well_uid: payload.params.well_uid}},
                                                                    exact: true
                                                                },
                                                            ]
                                                        },
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/engineering/design/details'),
                                                },
                                                {
                                                    path: 'scenario',
                                                    name: 'scenario-summary',
                                                    meta: {
                                                        title: 'Scenario Summary',
                                                        breadcrumbs: [
                                                            {text: 'Engineering', disabled: true},
                                                            {
                                                                text: 'Scenario Summary',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/engineering/scenario/summary'),
                                                },
                                                {
                                                    path: 'scenario/:scenario_id',
                                                    name: 'scenario-cases',
                                                    meta: {
                                                        title: 'Scenario',
                                                        breadcrumbs: (route) => {
                                                            const params = route.params
                                                            return [
                                                                {text: 'Engineering', disabled: true},
                                                                {
                                                                    text: 'Scenario Summary',
                                                                    disabled: false,
                                                                    to: {
                                                                        name: 'scenario-summary',
                                                                        params: {well_uid: params.well_uid}
                                                                    },
                                                                    exact: true
                                                                },
                                                            ]
                                                        },
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/engineering/scenario/details'),
                                                },
                                                {
                                                    path: 'drilling_program',
                                                    name: 'drilling-program-summary',
                                                    meta: {
                                                        title: 'Drilling Programs',
                                                        breadcrumbs: [
                                                            {text: 'Engineering', disabled: true},
                                                            {
                                                                text: 'Drilling Programs',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('@/components/tables/DrillingProgramSummary/DrillingProgramSummaryTable.vue'),
                                                },
                                                {
                                                    path: 'drilling_program/:drilling_program_id',
                                                    name: 'drilling-program-details',
                                                    meta: {
                                                        title: 'Drilling Programs',
                                                        breadcrumbs: (route) => {
                                                            const params = route.params
                                                            return [
                                                                {text: 'Engineering', disabled: true},
                                                                {
                                                                    text: 'Drilling Programs',
                                                                    disabled: false,
                                                                    to: {
                                                                        name: 'drilling-program-summary',
                                                                        params: {well_uid: params.well_uid}
                                                                    },
                                                                    exact: true
                                                                },
                                                            ]
                                                        },
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('@/views/engineering/drilling_program/DrillingProgramView.vue'),
                                                },
                                            ]
                                        },
                                        {
                                            path: 'well_header',
                                            meta: {
                                                title: 'Well Header',
                                                authRequired: true,
                                            },
                                            component: () => import(/* webpackChunkName: "reports" */ '../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: 'well_formations/:well_uid?',
                                                    name: 'well-formations',
                                                    meta: {
                                                        title: 'Formation Tops',
                                                        breadcrumbs: [
                                                            {text: 'Well Header', disabled: true},
                                                            {
                                                                text: 'Formation Tops',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/well_header/formations'),
                                                },
                                                {
                                                    path: 'well_pressure/:well_uid?',
                                                    name: 'well-pressure',
                                                    meta: {
                                                        title: 'Pressure',
                                                        breadcrumbs: [
                                                            {text: 'Well Header', disabled: true},
                                                            {
                                                                text: 'Pressure',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/well_header/pressure'),
                                                },
                                                {
                                                    path: 'well_temperature/:well_uid?',
                                                    name: 'well-temperature',
                                                    meta: {
                                                        title: 'Temperature',
                                                        breadcrumbs: [
                                                            {text: 'Well Header', disabled: true},
                                                            {
                                                                text: 'Temperature',
                                                                disabled: false,
                                                            },
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/well_header/temperature'),
                                                },
                                            ]
                                        },
                                        {
                                            path: 'data_insights',
                                            meta: {
                                                title: 'Data Insights',
                                                authRequired: true,
                                            },
                                            component: () => import(/* webpackChunkName: "reports" */ '../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: 'depth_analysis_log/:well_uid?',
                                                    name: 'depth_analysis_log',
                                                    meta: {
                                                        title: 'Depth Analysis Log',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "depth_log" */ '../views/recommendations/depth_analysis_log'),
                                                },
                                                {
                                                    path: 'time_analysis_log/:well_uid?',
                                                    name: 'time_analysis_log',
                                                    meta: {
                                                        title: 'Time Analysis Log',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "time_log" */ '../views/recommendations/time_log'),
                                                },
                                                {
                                                    path: 'custom_log/:well_uid?',
                                                    name: 'custom_log',
                                                    meta: {
                                                        title: 'Custom Well Log',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "custom_log" */ '../views/recommendations/custom_log'),
                                                },
                                                {
                                                    path: 'trouble_summary/:well_uid?',
                                                    name: 'trouble_summary',
                                                    meta: {
                                                        title: 'Trouble Summary',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "custom_log" */ '../views/recommendations/trouble_summary'),
                                                },
                                                {
                                                    path: 'recommendations/:well_uid?',
                                                    name: 'recommendations',
                                                    meta: {
                                                        title: 'Recommendations',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "custom_log" */ '../views/recommendations/recommendation_summary'),
                                                },
                                                {
                                                    path: 'torque_and_drag/:well_uid?',
                                                    redirect: {name: 'field_engineering'}
                                                },
                                                // *** BEGIN Redirection block BEGIN ***
                                                {
                                                    path: 'field_engineering/:well_uid?',
                                                    name: 'field_engineering',
                                                    meta: {
                                                        title: 'Field Engineering',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "tripping_log" */ '../views/recommendations/tripping_log'),
                                                },
                                                // *** END Redirection block END ***
                                                {
                                                    path: 'scatter_plot/:well_uid?',
                                                    name: 'scatter_plot',
                                                    meta: {
                                                        title: 'Scatter Plot',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "scatter_plot" */ '../views/recommendations/scatter_plot'),
                                                },
                                                {
                                                    path: '',
                                                    meta: {
                                                        title: 'Data Insights',
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/layouts/pages/PageWithBreadcrumbs.vue'),
                                                    children: [
                                                        {
                                                            path: ':well_uid?/roadmap',
                                                            name: 'roadmap',
                                                            meta: {
                                                                title: 'Roadmap Summary',
                                                                neededPermissions: [PERMISSIONS.VIEW_ROADMAP],
                                                                breadcrumbs: [
                                                                    {text: 'Data Insights', disabled: true},
                                                                    {
                                                                        text: 'Roadmap Summary',
                                                                        disabled: false,
                                                                    },
                                                                ],
                                                                hideBreadcrumbsInMobile: true,
                                                            },
                                                            component: () => import(/* webpackChunkName: "roadmap" */ '../views/recommendations/roadmap'),
                                                        },
                                                        {
                                                            path: ':well_uid?/roadmap/:roadmap_id?/interval/:interval_id?',
                                                            name: 'roadmap-configuration',
                                                            meta: {
                                                                title: 'Roadmap configuration',
                                                                neededPermissions: [PERMISSIONS.EDIT_ROADMAP],
                                                                breadcrumbs: (payload) => {
                                                                    return [
                                                                        {text: 'Data Insights', disabled: true},
                                                                        {
                                                                            text: 'Roadmap Summary',
                                                                            disabled: false,
                                                                            to: {name: 'roadmap', params: {well_uid: payload.params.well_uid}},
                                                                            exact: true
                                                                        },
                                                                    ]
                                                                },
                                                                hideBreadcrumbsInMobile: true,
                                                            },
                                                            component: () => import(/* webpackChunkName: "admin-dashboards" */ '../views/tools/roadmap_configuration'),
                                                        },
                                                    ],
                                                },
                                            ]
                                        },
                                        {
                                            path: 'reports',
                                            name: 'reports',
                                            meta: {
                                                title: 'Drilling Reports',
                                                authRequired: true,
                                            },
                                            component: () => import('@/views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: 'activities/:view/:well_uid?',
                                                    name: 'activities',
                                                    meta: {
                                                        title: 'Activities',
                                                    },
                                                    component: () => import(/* webpackChunkName: "activities" */ '../views/reports/activities'),
                                                },
                                                {
                                                    path: 'connections/:view/:well_uid?',
                                                    name: 'connections',
                                                    meta: {
                                                        title: 'Connections',
                                                    },
                                                    component: () => import(/* webpackChunkName: "connections" */ '../views/reports/connections'),
                                                },
                                                {
                                                    path: 'days_vs_depth/:well_uid?',
                                                    name: 'days_vs_depth',
                                                    meta: {
                                                        title: 'Days vs Depth',
                                                    },
                                                    component: () => import(/* webpackChunkName: "days_vs_depth" */ '../views/reports/days_vs_depth'),
                                                },
                                                {
                                                    path: 'drilling_stat/:well_uid?',
                                                    name: 'drilling_stat',
                                                    meta: {
                                                        title: 'Drilling Statistics',
                                                    },
                                                    component: () => import(/* webpackChunkName: "drilling_kips" */ '../views/reports/drilling_stat'),
                                                },
                                                {
                                                    path: 'rotary_analysis/:well_uid?',
                                                    name: 'rotary_analysis',
                                                    meta: {
                                                        title: 'Rotary Analysis',
                                                    },
                                                    component: () => import(/* webpackChunkName: "rotary_kips" */ '../views/reports/rotary_analysis'),
                                                },
                                                {
                                                    path: 'slide_analysis/:well_uid?',
                                                    name: 'slide_analysis',
                                                    meta: {
                                                        title: 'Slide Analysis',
                                                    },
                                                    component: () => import(/* webpackChunkName: "rotary_kips" */ '../views/reports/slide_analysis'),
                                                },
                                                {
                                                    path: 'slidesheet/:well_uid?',
                                                    name: 'slidesheet',
                                                    meta: {
                                                        title: 'Slidesheet',
                                                    },
                                                    component: () => import(/* webpackChunkName: "slidesheet" */ '../views/reports/slidesheet'),
                                                },
                                                {
                                                    path: 'time_log/:well_uid?',
                                                    name: 'time_log',
                                                    meta: {
                                                        title: 'Auto-Time Log',
                                                    },
                                                    component: () => import(/* webpackChunkName: "time_log" */ '../views/reports/time_log'),
                                                },
                                                {
                                                    path: 'daily_costs/:well_uid?',
                                                    name: 'daily_costs',
                                                    meta: {
                                                        title: 'Daily Cost',
                                                    },
                                                    component: () => import(/* webpackChunkName: "daily_costs" */ '../views/reports/daily_costs'),
                                                },

                                                {
                                                    path: ':well_uid/time_cost',
                                                    redirect: {name: 'time-cost-summary'},
                                                    component: () => import('../views/layouts/pages/PageWithBreadcrumbs.vue'),
                                                    children: [
                                                        {
                                                            path: '',
                                                            name: 'time-cost-summary',
                                                            meta: {
                                                                title: 'AFE Curves',
                                                                breadcrumbs: [
                                                                    {text: 'Drilling Reports', disabled: true},
                                                                    {
                                                                        text: 'AFE Curves',
                                                                        disabled: false,
                                                                    },
                                                                ],
                                                                hideBreadcrumbsInMobile: true,
                                                            },
                                                            component: () => import(/* webpackChunkName: "time-cost-summary" */ '../views/reports/time_costs/SummaryView.vue'),
                                                        },
                                                        {
                                                            path: ':estimate_id',
                                                            name: 'time-cost-details',
                                                            meta: {
                                                                title: 'AFE Curve',
                                                                breadcrumbs: (payload) => {
                                                                    return [
                                                                        {text: 'Drilling Reports', disabled: true},
                                                                        {
                                                                            text: 'AFE Curves',
                                                                            disabled: false,
                                                                            to: {name: 'time-cost-summary', params: {well_uid: payload.params.well_uid}},
                                                                            exact: true
                                                                        },
                                                                    ]
                                                                },
                                                                hideBreadcrumbsInMobile: true,
                                                            },

                                                            component: () => import(/* webpackChunkName: "time-cost-details" */ '../views/reports/time_costs/DetailsView.vue'),
                                                        },
                                                    ]
                                                },
                                                {
                                                    path: 'time_journal/:well_uid?',
                                                    name: 'time_journal',
                                                    meta: {
                                                        title: 'Time Journal',
                                                    },
                                                    component: () => import(/* webpackChunkName: "time_log" */ '../views/reports/time_journal'),
                                                },
                                                {
                                                    path: 'zero_statistics/:well_uid?',
                                                    name: 'zero_statistics',
                                                    meta: {
                                                        title: 'Zero Statistics',
                                                    },
                                                    component: () => import(/* webpackChunkName: "zero_statistics" */ '../views/reports/zero_statistics'),
                                                },
                                                {
                                                    path: 'tripping_stat/:well_uid?',
                                                    name: 'tripping_stat',
                                                    meta: {
                                                        title: 'Tripping Statistics',
                                                    },
                                                    component: () => import(/* webpackChunkName: "tripping_stat" */ '../views/reports/tripping_statistics'),
                                                },
                                                {
                                                    path: 'tripping_analysis/:view/:well_uid?',
                                                    name: 'tripping_analysis',
                                                    meta: {
                                                        title: 'Tripping Analysis',
                                                    },
                                                    component: () => import(/* webpackChunkName: "tripping_analysis" */ '../views/reports/tripping_analysis'),
                                                },
                                            ],
                                        },
                                        {
                                            path: 'directional_data',
                                            name: 'wps',
                                            meta: {
                                                title: 'Directional Data',
                                                authRequired: true,
                                            },
                                            component: () => import('../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: 'survey/:well_uid?',
                                                    name: 'survey',
                                                    meta: {
                                                        title: 'Actual Surveys',
                                                    },
                                                    component: () => import(/* webpackChunkName: "survey" */ '../views/wps/survey'),
                                                },
                                                {
                                                    path: 'plans/:well_uid?',
                                                    name: 'plans',
                                                    meta: {
                                                        title: 'Definitive Plan',
                                                    },
                                                    component: () => import(/* webpackChunkName: "plans" */ '../views/wps/plans'),
                                                },
                                                {
                                                    path: 'planned_trajectories/:well_uid?',
                                                    redirect: {name: 'planned_trajectories'},
                                                    meta: {
                                                        title: 'Planned Trajectories',
                                                    },
                                                    component: () => import('../views/layouts/pages/PageWithBreadcrumbs.vue'),
                                                    children: [
                                                        {
                                                            path: '',
                                                            name: 'planned_trajectories',
                                                            meta: {
                                                                title: 'Planned Trajectories',
                                                                breadcrumbs: [
                                                                    {text: 'Directional Data', disabled: true},
                                                                    {
                                                                        text: 'Planned Trajectories',
                                                                        disabled: false,
                                                                    },
                                                                ],
                                                                hideBreadcrumbsInMobile: true,
                                                            },
                                                            component: () => import(/* webpackChunkName: "planned_trajectories" */ '../views/wps/planned_trajectories'),
                                                        },
                                                        {
                                                            path: ':plan_id',
                                                            name: 'planned_trajectories_details',
                                                            meta: {
                                                                title: 'Planned Trajectories',
                                                                breadcrumbs: (payload) => {
                                                                    return [
                                                                        {text: 'Directional Data', disabled: true},
                                                                        {
                                                                            text: 'Planned Trajectories',
                                                                            disabled: false,
                                                                            to: {name: 'planned_trajectories', params: {well_uid: payload.params.well_uid}},
                                                                            exact: true
                                                                        },
                                                                    ]
                                                                },
                                                                hideBreadcrumbsInMobile: true,
                                                            },
                                                            component: () => import(/* webpackChunkName: "planned_trajectories_details" */ '../views/wps/planned_trajectories_details'),
                                                        },
                                                    ]
                                                },
                                                {
                                                    path: 'plan_vs_actual/:view/:well_uid?',
                                                    name: 'plan_vs_actual',
                                                    meta: {
                                                        title: 'Plan vs Actual',
                                                    },
                                                    component: () => import(/* webpackChunkName: "plan_vs_actual" */ '../views/wps/plan_vs_actual/drilling'),
                                                },
                                                {
                                                    path: 'geosteering/:well_uid?',
                                                    name: 'geosteering',
                                                    meta: {
                                                        title: 'Geosteering',
                                                    },
                                                    component: () => import(/* webpackChunkName: "geosteering" */ '../views/wps/geosteering'),
                                                },
                                                {
                                                    path: 'navigation/:view/:table/:well_uid?',
                                                    name: 'navigation',
                                                    meta: {
                                                        title: 'Navigation',
                                                    },
                                                    component: () => import(/* webpackChunkName: "navigation" */ '../views/wps/navigation'),
                                                },
                                                {
                                                    path: 'deviation/:well_uid?',
                                                    name: 'deviation',
                                                    meta: {
                                                        title: 'Deviation Analysis',
                                                    },
                                                    component: () => import(/* webpackChunkName: "deviation" */ '../views/wps/deviation'),
                                                },
                                                {
                                                    path: 'buildwalk/:well_uid?',
                                                    name: 'buildwalk',
                                                    meta: {
                                                        title: 'Build/Walk Analysis',
                                                    },
                                                    component: () => import(/* webpackChunkName: "buildwalk" */ '../views/wps/buildwalk'),
                                                },
                                                {
                                                    path: 'separation/:well_uid?',
                                                    name: 'separation',
                                                    meta: {
                                                        title: 'Separation',
                                                    },
                                                    component: () => import(/* webpackChunkName: "separation" */ '../views/wps/separation'),
                                                },
                                                {
                                                    path: 'ac_report/:view/:well_uid?',
                                                    name: 'ac_report',
                                                    meta: {
                                                        title: 'AC Report',
                                                    },
                                                    component: () => import(/* webpackChunkName: "ac_report" */ '../views/wps/ac_report'),
                                                },
                                                {
                                                    path: 'location/:well_uid?',
                                                    name: 'well_location',
                                                    meta: {
                                                        title: 'Location',
                                                    },
                                                    component: () => import(/* webpackChunkName: "well_location" */ '../views/wps/well_location'),
                                                },
                                            ],
                                        },
                                        {
                                            path: 'edm',
                                            name: 'edm',
                                            meta: {
                                                title: 'EDM Management',
                                                authRequired: true,
                                            },
                                            component: () => import('../views/layouts/pages/PageWithBreadcrumbs.vue'),
                                            children: [
                                                {
                                                    path: 'well/:well_uid?',
                                                    name: 'well-edm',
                                                    meta: {
                                                        title: 'Well AC Offsets',
                                                        breadcrumbs: [
                                                            {text: 'Well AC Offsets', disabled: true},
                                                        ],
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "edm" */ '../views/edm/well'),
                                                },
                                            ]
                                        },
                                        {
                                            path: 'file_drive',
                                            meta: {
                                                authRequired: true,
                                            },
                                            component: () => import('../views/layouts/pages/PageWithBreadcrumbs.vue'),
                                            children: [
                                                {
                                                    path: ':well_uid?',
                                                    name: 'file_drive',
                                                    meta: {
                                                        title: 'File Drive',
                                                        breadcrumbs: [
                                                            {text: 'File Drive', disabled: true},
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "file_drive" */ '../views/file_drive'),
                                                },
                                            ],
                                        },
                                        {
                                            path: 'management',
                                            name: 'management',
                                            // redirect: 'admin/dashboard',
                                            meta: {
                                                title: 'Tools',
                                            },
                                            component: () => import('../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: 'control/:well_uid?',
                                                    name: 'tools-control',
                                                    meta: {
                                                        title: 'Well tuner',
                                                        neededPermissions: [PERMISSIONS.WELL_PROCESSING],
                                                    },
                                                    component: () => import(/* webpackChunkName: "tools-control" */ '../views/tools/visual_control'),
                                                },
                                                {
                                                    path: 'well_configuration/:well_uid?',
                                                    name: 'well-configuration',
                                                    meta: {
                                                        title: 'Well configuration',
                                                        neededPermissions: [PERMISSIONS.WELL_PROCESSING],
                                                    },
                                                    component: () => import(/* webpackChunkName: "well-configuration" */ '../views/tools/well_configuration'),
                                                },
                                                // {
                                                //     path: 'completions-control/:well_uid?',
                                                //     name: 'completions-control',
                                                //     meta: {
                                                //         title: 'Completions control',
                                                //         // neededPermissions: [PERMISSIONS.WELL_PROCESSING],
                                                //     },
                                                //     // component: () => import(/* webpackChunkName: "admin-dashboards" */ '../views/tools/well_configuration'),
                                                // },
        
                                            ],
                                        },
                                    ],
                                },
                                {
                                    path: 'completions',
                                    redirect: {name: 'completions-dashboards'},
                                    // redirect: {name: 'completions-treatment_plot'},
                                    meta: {
                                        title: 'Completions',
                                        authRequired: true,
                                        hasProfileFlag: 'completion_enabled',
                                    },
                                    component: () => import('../views/layouts/CompletionsLayout.vue'),
                                    children: [
                                        {
                                            path: 'dashboards/:well_uid?',
                                            name: 'completions-dashboards',
                                            meta: {
                                                title: 'Dashboards',
                                                authRequired: true,
                                            },
                                            component: () => import(/* webpackChunkName: "completions-dashboards" */ '../views/completions/dashboard'),
                                        },
                                        {
                                            path: 'data_insights',
                                            name: 'completions-recommendations',
                                            meta: {
                                                title: 'Data Insights',
                                                authRequired: true,
                                            },
                                            component: () => import('../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: 'treatment_plot/:well_uid?',
                                                    name: 'completions-treatment_plot',
                                                    meta: {
                                                        title: 'Treatment Time Log',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "completions-treatment_plot" */ '../views/completions/data_insights/time_log'),
                                                },
                                                {
                                                    path: 'custom_log/:well_uid?',
                                                    name: 'completions-custom_log',
                                                    meta: {
                                                        title: 'Custom Time Log',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "completions-custom_log" */ '../views/completions/data_insights/custom_log'),
                                                },
                                                {
                                                    path: 'trouble_summary/:well_uid?',
                                                    name: 'completions_trouble_summary',
                                                    meta: {
                                                        title: 'Trouble Summary',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "custom_log" */ '../views/completions/data_insights/trouble_summary'),
                                                },
                                                {
                                                    path: 'recommendations/:well_uid?',
                                                    name: 'completions_recommendations',
                                                    meta: {
                                                        title: 'Recommendations',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "custom_log" */ '../views/completions/data_insights/recommendation_summary'),
                                                },
                                                {
                                                    path: 'wireline_time_log/:well_uid?',
                                                    name: 'completions-wireline_time_log',
                                                    meta: {
                                                        title: 'Wireline Time Log',
                                                        authRequired: true,
                                                    },
                                                },
                                                {
                                                    path: 'drill_out_time_log/:well_uid?',
                                                    name: 'completions-drill_out_time_log',
                                                    meta: {
                                                        title: 'Drill Out Time Log',
                                                        authRequired: true,
                                                    },
                                                },
                                            ]
                                        },
                                        {
                                            path: 'completion_reports',
                                            name: 'completion-reports',
                                            meta: {
                                                title: 'Completion Reports',
                                                authRequired: true,
                                            },
                                            component: () => import('../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: 'interval_summary/:well_uid?',
                                                    name: 'completion-interval_summary',
                                                    meta: {
                                                        title: 'Interval Summary',
                                                        authRequired: true,
                                                    },
                                                    component: () => import('../views/completions/completion_reports/interval_summary/index.vue'),
                                                },
                                                {
                                                    path: 'pumping_summary/:well_uid?',
                                                    name: 'completion-pumping_summary',
                                                    meta: {
                                                        title: 'Pumping Summary',
                                                        authRequired: true,
                                                    },
                                                },
                                                {
                                                    path: 'time_journal/:well_uid?',
                                                    name: 'completion-time_journal',
                                                    meta: {
                                                        title: 'Time Journal',
                                                    },
                                                    component: () => import(/* webpackChunkName: "time_log" */ '../views/completions/completion_reports/time_journal'),
                                                },
                                                {
                                                    path: 'daily_costs/:well_uid?',
                                                    name: 'completion-daily_costs',
                                                    meta: {
                                                        title: 'Daily Cost',
                                                        authRequired: true,
                                                    },
                                                    component: () => import(/* webpackChunkName: "completion-daily_costs" */ '../views/completions/completion_reports/daily_costs/index.vue'),
                                                },
                                            ]
                                        },
                                        {
                                            path: 'drilling_reports',
                                            name: 'completions-drilling_reports',
                                            meta: {
                                                title: 'Drilling Reports',
                                                authRequired: true,
                                            },
                                            component: () => import('../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: 'survey/:well_uid?',
                                                    name: 'completion_survey',
                                                    meta: {
                                                        title: 'Actual Surveys',
                                                    },
                                                    component: () => import(/* webpackChunkName: "survey" */ '../views/wps/survey'),
                                                },
                                                {
                                                    path: 'plan_vs_actual/:view/:well_uid?',
                                                    name: 'completions-plan_vs_actual',
                                                    meta: {
                                                        title: 'Plan vs Actual',
                                                    },
                                                    component: () => import(/* webpackChunkName: "plan_vs_actual" */ '../views/completions/drilling_reports/plan_vs_actual'),
                                                },
                                                {
                                                    path: 'geosteering/:well_uid?',
                                                    name: 'completions-geosteering',
                                                    meta: {
                                                        title: 'Geosteering',
                                                    },
                                                    component: () => import(/* webpackChunkName: "geosteering" */ '../views/wps/geosteering'),
                                                },
                                                {
                                                    path: 'location/:well_uid?',
                                                    name: 'completions-well_location',
                                                    meta: {
                                                        title: 'Location',
                                                    },
                                                    component: () => import(/* webpackChunkName: "well_location" */ '../views/completions/drilling_reports/well_location'),
                                                },
                                                {
                                                    path: 'well/:well_uid?',
                                                    redirect: {name: 'completion_design-summary'},
                                                    meta: {
                                                        title: 'Drilling Reports',
                                                        authRequired: true,
                                                        hideBreadcrumbsInMobile: true,
                                                    },
                                                    component: () => import('../views/layouts/pages/PageWithBreadcrumbs.vue'),
                                                    children: [
                                                        {
                                                            path: 'design',
                                                            name: 'completion_design-summary',
                                                            meta: {
                                                                title: 'Design Summary',
                                                                breadcrumbs: [
                                                                    {text: 'Drilling Reports', disabled: true},
                                                                    {
                                                                        text: 'Design Summary',
                                                                        disabled: false,
                                                                    },
                                                                ],
                                                                hideBreadcrumbsInMobile: true,
                                                            },
                                                            component: () => import('../views/engineering/design/summary'),
                                                        },
                                                        {
                                                            path: 'design/:design_id',
                                                            name: 'completion_design-details',
                                                            meta: {
                                                                title: 'Wellbore Design',
                                                                breadcrumbs: (payload) => {
                                                                    return [
                                                                        {text: 'Drilling Reports', disabled: true},
                                                                        {
                                                                            text: 'Design Summary',
                                                                            disabled: false,
                                                                            to: {name: 'completion_design-summary', params: {well_uid: payload.params.well_uid}},
                                                                            exact: true
                                                                        },
                                                                    ]
                                                                },
                                                                hideBreadcrumbsInMobile: true,
                                                            },
                                                            component: () => import('../views/engineering/design/details'),
                                                        },
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            path: 'file_drive',
                                            name: 'completions-file_drive',
                                            meta: {
                                                authRequired: true,
                                            },
                                            component: () => import('../views/layouts/pages/PageWithBreadcrumbs.vue'),
                                            children: [
                                                {
                                                    path: ':well_uid?',
                                                    name: 'completions-file_drive-well',
                                                    meta: {
                                                        title: 'File Drive',
                                                        breadcrumbs: [
                                                            {text: 'File Drive', disabled: true},
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "file_drive" */ '../views/file_drive'),
                                                    beforeEnter: (to, from, next) => {
                                                        if (!to.query.folder_id) {
                                                            next({path: to.path, query: {...to.query, folder_id: FILE_DRIVE_FOLDER_ID.COMPLETIONS}})
                                                        } else {
                                                            next()
                                                        }
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            path: 'management',
                                            name: 'completions-management',
                                            meta: {
                                                title: 'Tools',
                                            },
                                            component: () => import('../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: 'well_configuration/:well_uid?',
                                                    name: 'completions-well_configuration',
                                                    meta: {
                                                        title: 'Well configuration',
                                                        neededPermissions: [PERMISSIONS.WELL_PROCESSING],
                                                    },
                                                    component: () => import(/* webpackChunkName: "completions-well_configuration" */ '../views/tools/well_configuration'),
                                                },
                                                {
                                                    path: 'control/:well_uid?',
                                                    name: 'completions-control',
                                                    meta: {
                                                        title: 'Completions Control Log',
                                                        // neededPermissions: [PERMISSIONS.WELL_PROCESSING],
                                                    },
                                                    component: () => import(/* webpackChunkName: "completions-control" */ '../views/completions/tools/control_log'),
                                                },
        
                                            ],
                                        },
                                        {
                                            path: 'equipment/well/:well_uid?',
                                            name: 'completions-equipment',
                                            meta: {
                                                title: 'Equipment',
                                                authRequired: true,
                                                hideBreadcrumbsInMobile: true,
                                            },
                                            component: () => import('../views/layouts/pages/PageWithBreadcrumbs.vue'),
                                            children: [
                                                {
                                                    path: 'tickets',
                                                    name: 'completions-tickets-summary',
                                                    meta: {
                                                        title: 'Field Tickets',
                                                        breadcrumbs: [
                                                            {text: 'Equipment', disabled: true},
                                                            {text: 'Field Tickets', disabled: false},
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "tickets" */ '../views/completions/tickets'),
                                                },
                                                {
                                                    path: 'tickets/:ticket_id',
                                                    name: 'completions-tickets-details',
                                                    meta: {
                                                        title: 'Ticket',
                                                        breadcrumbs: (payload) => {
                                                            return [
                                                                {text: 'Equipment', disabled: true},
                                                                {
                                                                    text: 'Field Tickets',
                                                                    disabled: false,
                                                                    to: {name: 'completions-tickets-summary', params: {well_uid: payload.params.well_uid}},
                                                                    exact: true
                                                                },
                                                            ]
                                                        },
                                                    },
                                                    component: () => import(/* webpackChunkName: "tickets" */ '../views/completions/tickets/ticket'),
                                                },
                                            ]
                                        },
                                    ],
                                },
                                {
                                    // path: 'well/:well_uid?/widget/:fragment_id?/mode/:layout_mode?',
                                    path: 'well/:well_uid?/widget/:fragment_id?/mode/:layout_mode',
                                    name: 'one_single_well_widget',
                                    meta: {
                                        title: 'AI Driller',
                                        authRequired: true,
                                        isOneWidget: true,
                                    },
                                    props: route => ({
                                        fragmentId: route.params.fragment_id,
                                        layoutMode: route.params.layout_mode
                                    }),
                                    component: () => import('../views/layouts/OneSingleWellWidget'),
                                },
                            ],
                        },
                        {
                            path: 'business',
                            redirect: {name: 'multiwell-drilling_dashboards'},
                            meta: {
                                title: 'Multiwell',
                                authRequired: true,
                                hasProfileFlag: 'multi_well_enabled',
                            },
                            component: () => import('../views/layouts/MultiWellLayout.vue'),
                            children: [
                                {
                                    path: 'drilling/dashboards',
                                    name: 'multiwell-drilling_dashboards',
                                    meta: {
                                        title: 'Multiwell Drilling - Dashboards',
                                        authRequired: true,
                                    },
                                    component: () => import(/* webpackChunkName: "multiwell-drilling_dashboards" */ '../views/multiwell/drilling/dashboard'),
                                },
                                {
                                    path: 'completions/dashboards',
                                    name: 'multiwell-completions_dashboards',
                                    meta: {
                                        title: 'Multiwell Completions - Dashboards',
                                        authRequired: true,
                                        hasProfileFlag: 'completion_enabled',
                                        hasProfileFlagRedirect: 'multiwell-drilling_dashboards'
                                    },
                                    component: () => import(/* webpackChunkName: "multiwell-completions_dashboards" */ '../views/multiwell/completions/dashboard'),
                                },
                                // {
                                //     path: 'schedule',
                                //     name: 'multiwell-schedules',
                                //     meta: {
                                //         title: 'Schedule Boards',
                                //         authRequired: true,
                                //     },
                                //     component: () => import(/* webpackChunkName: "multiwell-schedules" */ '../views/multiwell/schedule'),
                                // },
                            ],
                        },
                        {
                            path: 'project',
                            redirect: {name: 'project_dashboards'},
                            meta: {
                                title: 'Project',
                                authRequired: true,
                            },
                            component: () => import('../views/layouts/ProjectLayout.vue'),
                            children: [
                                {
                                    path: 'dashboards',
                                    name: 'project_dashboards',
                                    meta: {
                                        title: 'Project - Dashboards',
                                        authRequired: true,
                                    },
                                    component: () => import(/* webpackChunkName: "project_dashboards" */ '../views/project/ProjectDashboardView.vue'),
                                },
                            ],
                        },
                        {
                            path: 'well_planning',
                            redirect: {name: 'well_planning'},
                            meta: {
                                title: 'Well Planning',
                                authRequired: true,
                            },
                            component: () => import('../views/layouts/WellPlanningLayout.vue'),
                            children: [
                                {
                                    path: ':project_id?',
                                    name: 'well_planning',
                                    props: true,
                                    meta: {
                                        title: 'Well Planning',
                                        breadcrumbs: [
                                            {text: 'Well Planning', disabled: true},
                                        ],
                                        hideBreadcrumbsInMobile: true,
                                        neededPermissions: [PERMISSIONS.VIEW_PROJECT_EDM],
                                    },
                                    component: () => import(/* webpackChunkName: "well_planning" */ '../views/well_planning/WellPlanningView.vue'),
                                },
                            ],
                        },
                        {
                            path: 'admin',
                            meta: {
                                title: 'Admin',
                                breadcrumbs: [
                                    {text: 'Administration', disabled: true},
                                ],
                            },
                            component: () => import('../views/layouts/AdminLayout.vue'),
                            children: [
                                {
                                    path: '',
                                    redirect: {name: 'admin-dashboards'},
                                    meta: {
                                        authRequired: true,
                                    },
                                    component: () => import('../views/layouts/pages/PageWithBreadcrumbs.vue'),
                                    children: [
                                        {
                                            path: 'dashboards',
                                            name: 'admin-dashboards',
                                            redirect: {name: 'admin-dashboard_wells'},
                                            meta: {
                                                isAdminRequired: true,
                                                title: 'Dashboards',
                                                breadcrumbs: [
                                                    {text: 'Administration', disabled: true},
                                                    {text: 'Dashboards', disabled: false},
                                                ],
                                            },
                                            component: () => import(/* webpackChunkName: "admin-dashboards" */ '../views/admin/dashboard'),
                                            children: [
                                                {
                                                    path: 'wells',
                                                    name: 'admin-dashboard_wells',
                                                    meta: {
                                                        title: 'Wells Dashboard',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Dashboards',
                                                                disabled: false,
                                                                to: {name: 'admin-dashboards'},
                                                                exact: true
                                                            },
                                                            {text: 'Active Wells', disabled: false},
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-dashboard_wells" */ '../views/admin/dashboard/wells'),
                                                },
                                                {
                                                    path: 'well_data_sources',
                                                    name: 'admin-dashboard_well_data_sources',
                                                    meta: {
                                                        title: 'Well Data Sources Dashboard',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Dashboards',
                                                                disabled: false,
                                                                to: {name: 'admin-dashboards'},
                                                                exact: true
                                                            },
                                                            {text: 'Well Data Sources', disabled: false},
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-dashboard_wells" */ '../views/admin/dashboard/well_data_sources'),
                                                },
                                                {
                                                    path: 'trajectory_data_sources',
                                                    name: 'admin-dashboard_trajectory_data_sources',
                                                    meta: {
                                                        title: 'Trajectory Data Sources Dashboard',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Dashboards',
                                                                disabled: false,
                                                                to: {name: 'admin-dashboards'},
                                                                exact: true
                                                            },
                                                            {text: 'Trajectory Data Sources', disabled: false},
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-dashboard_wells" */ '../views/admin/dashboard/trajectory_data_sources'),
                                                },
                                                {
                                                    path: 'messages_data_sources',
                                                    name: 'admin-dashboard_messages_data_sources',
                                                    meta: {
                                                        title: 'Messages Data Sources Dashboard',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Dashboards',
                                                                disabled: false,
                                                                to: {name: 'admin-dashboards'},
                                                                exact: true
                                                            },
                                                            {text: 'Messages Data Sources', disabled: false},
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-dashboard_messages_data_sources" */ '../views/admin/dashboard/messages_data_sources'),
                                                },
                                                {
                                                    path: 'dummy_sms',
                                                    name: 'admin-dashboard_dummy-sms',
                                                    meta: {
                                                        title: 'Dummy SMS Dashboard',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Dashboards',
                                                                disabled: false,
                                                                to: {name: 'admin-dashboards'},
                                                                exact: true
                                                            },
                                                            {text: 'Dummy SMS', disabled: false},
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-dashboard_dummy-sms" */ '../views/admin/dashboard/dummy_sms'),
                                                },
                                                {
                                                    path: 'jobs',
                                                    name: 'admin-dashboard_jobs',
                                                    meta: {
                                                        title: 'Jobs Dashboard',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Dashboards',
                                                                disabled: false,
                                                                to: {name: 'admin-dashboards'},
                                                                exact: true
                                                            },
                                                            {text: 'Jobs', disabled: false},
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-dashboard_jobs" */ '../views/admin/dashboard/jobs'),
                                                },
                                            ],
                                        },
                                        {
                                            path: 'users',
                                            meta: {
                                                isAdminRequired: true,
                                                title: 'Users',
                                                breadcrumbs: [
                                                    {text: 'Administration', disabled: true},
                                                    {text: 'Users', disabled: false}
                                                ],
                                            },
                                            component: () => import('../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'admin-users',
                                                    meta: {
                                                        savedSearchKey: SAVED_SEARCH_KEYS.ADMIN_USERS,
                                                        title: 'Users',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Users',
                                                                disabled: false,
                                                            },
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-users" */ '../views/admin/users'),
                                                },
                                                {
                                                    path: ':id',
                                                    name: 'admin-user',
                                                    meta: {
                                                        savedSearchKey: SAVED_SEARCH_KEYS.ADMIN_USERS,
                                                        title: 'User',
                                                        breadcrumb: '${userName}',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Users',
                                                                disabled: false,
                                                                to: {name: 'admin-users'},
                                                                exact: true
                                                            },
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-user" */ '../views/admin/users/user'),
                                                },
                                            ]
                                        },
                                        {
                                            path: 'companies',
                                            meta: {
                                                isAdminRequired: true,
                                                title: 'Companies',
                                                breadcrumb: 'Companies',
                                                breadcrumbs: [
                                                    {text: 'Administration', disabled: true},
                                                    {text: 'Companies', disabled: false}
                                                ],
                                            },
                                            component: () => import('../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'admin-companies',
                                                    meta: {
                                                        savedSearchKey: SAVED_SEARCH_KEYS.ADMIN_COMPANIES,
                                                        title: 'Company',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Companies',
                                                                disabled: false,
                                                            },
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-companies" */ '../views/admin/companies'),
                                                },
                                                {
                                                    path: ':id',
                                                    name: 'admin-company',
                                                    meta: {
                                                        savedSearchKey: SAVED_SEARCH_KEYS.ADMIN_COMPANIES,
                                                        title: 'Company',
                                                        breadcrumb: '${companyName}',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Companies',
                                                                disabled: false,
                                                                to: {name: 'admin-companies'},
                                                                exact: true
                                                            },
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-company" */ '../views/admin/companies/company'),
                                                },
                                            ]
                                        },
                                        {
                                            path: 'bids',
                                            meta: {
                                                isAdminRequired: true,
                                                title: 'Bids',
                                                breadcrumb: 'Bids',
                                                breadcrumbs: [
                                                    {text: 'Administration', disabled: true},
                                                    {text: 'Bids', disabled: false}
                                                ],
                                            },
                                            component: () => import('../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'admin-bids',
                                                    meta: {
                                                        title: 'Bid',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Bids',
                                                                disabled: false,
                                                            },
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-bids" */ '../views/admin/bids'),
                                                },
                                                {
                                                    path: ':id',
                                                    name: 'admin-bid',
                                                    meta: {
                                                        title: 'Bid',
                                                        breadcrumb: '${bidName}',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Bids',
                                                                disabled: false,
                                                                to: {name: 'admin-bids'},
                                                                exact: true
                                                            },
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-bids" */ '../views/admin/bids/bid'),
                                                },
                                            ],
                                        },
                                        {
                                            path: 'projects',
                                            meta: {
                                                isAdminRequired: true,
                                                title: 'Projects',
                                                breadcrumb: 'Projects',
                                                breadcrumbs: [
                                                    {text: 'Administration', disabled: true},
                                                    {text: 'Projects', disabled: false}
                                                ],
                                            },
                                            component: () => import('../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'admin-projects',
                                                    meta: {
                                                        savedSearchKey: SAVED_SEARCH_KEYS.ADMIN_PROJECTS,
                                                        title: 'Project',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Projects',
                                                                disabled: false,
                                                            },
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-companies" */ '../views/admin/projects'),
                                                },
                                                {
                                                    path: ':id',
                                                    name: 'admin-project',
                                                    meta: {
                                                        savedSearchKey: SAVED_SEARCH_KEYS.ADMIN_PROJECTS,
                                                        title: 'Project',
                                                        breadcrumb: '${projectName}',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Projects',
                                                                disabled: false,
                                                                to: {name: 'admin-projects'},
                                                                exact: true
                                                            },
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-company" */ '../views/admin/projects/project'),
                                                },
                                            ]
                                        },
                                        {
                                            path: 'wells',
                                            meta: {
                                                title: 'Wells',
                                                breadcrumbs: [
                                                    {text: 'Administration', disabled: true},
                                                    {text: 'Wells', disabled: false}
                                                ],
                                            },
                                            component: () => import('../views/layouts/EmptyLayout.vue'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'admin-wells',
                                                    meta: {
                                                        savedSearchKey: SAVED_SEARCH_KEYS.ADMIN_WELLS,
                                                        title: 'Wells',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Wells',
                                                                disabled: false,
                                                            },
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-wells" */ '../views/admin/wells'),
                                                },
                                                {
                                                    path: ':id',
                                                    name: 'admin-well',
                                                    meta: {
                                                        savedSearchKey: SAVED_SEARCH_KEYS.ADMIN_WELLS,
                                                        title: 'Wells',
                                                        breadcrumb: '${wellName}',
                                                        breadcrumbs: [
                                                            {text: 'Administration', disabled: true},
                                                            {
                                                                text: 'Wells',
                                                                disabled: false,
                                                                to: {name: 'admin-wells'},
                                                                exact: true
                                                            },
                                                        ],
                                                    },
                                                    component: () => import(/* webpackChunkName: "admin-well" */ '../views/admin/wells/well'),
                                                },

                                            ],
                                        },
                                        {
                                            path: 'wells_audit',
                                            name: 'wells-audit',
                                            meta: {
                                                isAdminRequired: true,
                                                title: 'Wells Audit',
                                                breadcrumb: 'Wells Audit',
                                                breadcrumbs: [
                                                    {text: 'Administration', disabled: true},
                                                    {text: 'Wells Audit', disabled: false}
                                                ],
                                            },
                                            component: () => import(/* webpackChunkName: "wells_audit" */ '../views/admin/wells_audit'),
                                        },
                                        {
                                            path: 'external_providers',
                                            name: 'ext-providers',
                                            meta: {
                                                isAdminRequired: true,
                                                title: 'External Providers',
                                                breadcrumb: 'External Providers',
                                                breadcrumbs: [
                                                    {text: 'Administration', disabled: true},
                                                    {text: 'External Providers', disabled: false}
                                                ],
                                            },
                                            component: () => import(/* webpackChunkName: "ext_providers" */ '../views/admin/ext_providers'),
                                        },
                                        {
                                            path: 'system_settings/:tab?',
                                            name: 'system-settings',
                                            meta: {
                                                isAdminRequired: true,
                                                title: 'System Settings',
                                                breadcrumb: 'System Settings',
                                                breadcrumbs: [
                                                    {text: 'Administration', disabled: true},
                                                    {text: 'System Settings', disabled: false}
                                                ],
                                            },
                                            component: () => import(/* webpackChunkName: "system_settings" */ '../views/admin/system_settings'),
                                        },
                                        {
                                            path: 'system_audit',
                                            name: 'system-audit',
                                            meta: {
                                                isAdminRequired: true,
                                                title: 'System Audit',
                                                breadcrumb: 'System Audit',
                                                breadcrumbs: [
                                                    {text: 'Administration', disabled: true},
                                                    {text: 'System Audit', disabled: false}
                                                ],
                                            },
                                            component: () => import(/* webpackChunkName: "system_audit" */ '../views/admin/system_audit'),
                                        },
                                        {
                                            path: 'global_access',
                                            name: 'global-access',
                                            meta: {
                                                isAdminRequired: true,
                                                title: 'Global Access',
                                                breadcrumb: 'Global Access',
                                                breadcrumbs: [
                                                    {text: 'Administration', disabled: true},
                                                    {text: 'Global Access', disabled: false}
                                                ],
                                            },
                                            component: () => import(/* webpackChunkName: "global_access" */ '../views/admin/global_access'),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'print',
                            name: 'print',
                            meta: {
                                title: 'Print',
                                authRequired: true,
                            },
                            component: () => import('../views/layouts/PrintLayout.vue'),
                        },
                        {
                            // path: 'well/:well_uid?/widget/:fragment_id?/mode/:layout_mode?',
                            path: 'widget/:fragment_id?/mode/:layout_mode',
                            name: 'one_widget',
                            meta: {
                                title: 'AI Driller',
                                authRequired: true,
                                isOneWidget: true,
                            },
                            props: route => ({
                                fragmentId: route.params.fragment_id,
                                layoutMode: route.params.layout_mode
                            }),
                            component: () => import('../views/layouts/OneWidget'),
                        }
                    ],
                },
            ],
        },
        { // Redirect all other requests to 404
            path: '*', redirect: {name: '404'}, hidden: true,
        },
    ],
})

router.beforeEach((to, from, next) => {
    // change page title from meta
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
    document.title = mainTitle + (nearestWithTitle ? ': ' + nearestWithTitle.meta.title : '')

    if (to.meta.disabled) {
        return next({
            name: '404',
        })
    }

    if (to.matched.some(record => record.meta.onlySuperadmin)) {
        if (!store.getters.isAdmin) {
            return next({
                name: defaultRouteName,
                params: to.params
            })
        }
    }

    if (to.matched.some(record => record.meta.authRequired)) {
        if (!store.getters.isAuthenticated) {
            next({
                name: 'sign-in',
                query: {redirect: window.location.pathname},
            })
            return
        }
    }

    if (to.matched.some(record => record.meta.isAdminRequired)) {
        // if (to.matched.some(record => record.meta.isAdminRequired) ||
        //     _.get(to, 'meta.neededPermissions', []).some(permission => !store.getters.hasPermission(permission))) {
        document.title = mainTitle + ' Administration' + (nearestWithTitle ? ': ' + nearestWithTitle.meta.title : '')
        if (!store.getters.isAuthenticated || !store.getters.isAdmin) {
            next({
                name: '403',
                query: {redirect: to.fullPath},
            })
            return
        }
    }

    if (store.getters.isEditMode) {
        router.app.$eventHub.$emit('route-change-requested', next)
    } else {
        if (to.name !== from.name) {
            router.app.$eventHub.$emit('route-changed')
        }
        next()
    }
})

router.beforeResolve((to, from, next) => {
    const profileAccessFlags = to.matched.filter(record => record.meta.hasProfileFlag)
    // for case when UserProfile already received, otherwise see auth.USER_SUCCESS
    if (profileAccessFlags && profileAccessFlags.length && !_.isEmpty(store.getters.getProfile) && !store.getters.isAdmin) {
        if (!profileAccessFlags.every(item => store.getters.getProfile[item.meta.hasProfileFlag])) {
            next({
                name: '403',
                query: {redirect: to.fullPath},
            })
            return
        }
    }
    next()
})

export function getRouteMetaByName(name) {
    const route = router.getRoutes().find(record => record.name === name)
    if (!route) {
        console.error(`Can't find route by name: ${name}`)
        return {}
    }

    return route.meta
}

export default router
