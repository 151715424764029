import Enum from "@/utils/enum"
import {CRIMSON, FONT_BLUE, ORANGE} from "@/assets/charts/themes"
import {MS_IN_HOUR, MS_IN_MINUTE} from "@/assets/constants"
import {IProjectionMethod} from "@/components/ProjectionFormContent/interfaces"

export enum THEMES {
    LIGHT = 'light',
    DARK = 'dark',
    BLACK = 'black'
}

export enum SKIN {
    DEFAULT = 'default',
    BORDERED = 'bordered'
}

export enum PERMISSIONS {
    UNKNOWN = "UNKNOWN",
    VIEW = "VIEW",
    EDIT_SURVEY = "EDIT_SURVEY",
    EDIT_PLAN = "EDIT_PLAN",
    EDIT_CONNECTION_REPORT = "EDIT_CONNECTION_REPORT",
    VIEW_ROADMAP = "VIEW_ROADMAP",
    EDIT_BACK_TO_PLAN = "EDIT_BACK_TO_PLAN",
    EDIT_PROJECTION = "EDIT_PROJECTION",
    CREATE_PROJECTION_FOR_EVERYONE = "CREATE_PROJECTION_FOR_EVERYONE",
    USE_SYNT_WELL = "USE_SYNT_WELL",
    FILE_DRIVE = "FILE_DRIVE",
    UPLOAD_FILE = "UPLOAD_FILE",
    READ_OWN_FILES = "READ_OWN_FILES",
    READ_ALL_FILES = "READ_ALL_FILES",
    MODIFY_DELETE_OWN_FILES = "MODIFY_DELETE_OWN_FILES",
    MODIFY_DELETE_ALL_FILES = "MODIFY_DELETE_ALL_FILES",
    WELL_PROCESSING = "WELL_PROCESSING",
    WELL_DEBUGGING = "WELL_DEBUGGING",
    USE_PLAYER = "USE_PLAYER",
    VIEW_WELL_CONFIGURATION = "VIEW_WELL_CONFIGURATION",
    EDIT_WELL_RIGS = "EDIT_WELL_RIGS",
    VIEW_WELL_FRAC_FLEET = "VIEW_WELL_FRAC_FLEET",
    EDIT_WELL_FRAC_FLEET = "EDIT_WELL_FRAC_FLEET",
    VIEW_SURVEY_HISTORY = "VIEW_SURVEY_HISTORY",
    USE_TEST_CASES = "USE_TEST_CASES",
    EDIT_WELL_BHA = "EDIT_WELL_BHA",
    SURVEY_RELOAD_FROM_DS = 'SURVEY_RELOAD_FROM_DS',
    EDIT_FORMATION = 'EDIT_FORMATION',
    EDIT_RUN_TYPE = 'EDIT_RUN_TYPE',
    MANAGE_WELLS = 'MANAGE_WELLS',
    MANAGE_USERS = 'MANAGE_USERS',
    MANAGE_WELLS_VISIBILITY = 'MANAGE_WELLS_VISIBILITY',
    VIEW_DESIGN = 'VIEW_DESIGN',
    EDIT_DESIGN = 'EDIT_DESIGN',
    VIEW_SCENARIO = 'VIEW_SCENARIO',
    EDIT_SCENARIO = 'EDIT_SCENARIO',
    VIEW_MUD = 'VIEW_MUD',
    EDIT_MUD = 'EDIT_MUD',
    IMPORT_MUD = 'IMPORT_MUD',
    EDIT_ROADMAP = 'EDIT_ROADMAP',
    VIEW_PROJECT_EDM = 'VIEW_PROJECT_EDM',
    EDIT_PROJECT_EDM = 'EDIT_PROJECT_EDM',
    EXPORT_PROJECT_EDM = 'EXPORT_PROJECT_EDM',
    IMPORT_PROJECT_EDM = 'IMPORT_PROJECT_EDM',
    DELETE_EDM_VERSIONS = 'DELETE_EDM_VERSIONS',
    MANAGE_DEFAULT_OFFSETS = 'MANAGE_DEFAULT_OFFSETS',
    COMPLETION_MANAGEMENT = 'COMPLETION_MANAGEMENT',
    EDIT_COMPLETION = 'EDIT_COMPLETION',
    VIEW_COMPLETION = 'VIEW_COMPLETION',
    EVERYONE_USER_COMMENTS = 'EVERYONE_USER_COMMENTS',
    ONLY_ME_USER_COMMENTS = 'ONLY_ME_USER_COMMENTS',
    DOWNLOAD_EOU_REPORT = 'DOWNLOAD_EOU_REPORT',
    VIEW_DRILLING_COSTS = 'VIEW_DRILLING_COSTS',
    VIEW_COMPLETION_COSTS = 'VIEW_COMPLETION_COSTS',
    EDIT_DRILLING_COSTS = 'EDIT_DRILLING_COSTS',
    EDIT_COMPLETION_COSTS = 'EDIT_COMPLETION_COSTS',
    VIEW_INCIDENTS = 'VIEW_INCIDENTS',
    ADD_EDIT_INCIDENTS = 'ADD_EDIT_INCIDENTS',
    APPROVE_INCIDENTS = 'APPROVE_INCIDENTS',
    VIEW_RECOMMENDATIONS = 'VIEW_RECOMMENDATIONS',
    ADD_EDIT_RECOMMENDATIONS = 'ADD_EDIT_RECOMMENDATIONS',
    VIEW_DRILLING_TIMELOG = 'VIEW_DRILLING_TIMELOG',
    EDIT_DRILLING_TIMELOG = 'EDIT_DRILLING_TIMELOG',
    VIEW_COMPLETION_TIMELOG = 'VIEW_COMPLETION_TIMELOG',
    EDIT_COMPLETION_TIMELOG = 'EDIT_COMPLETION_TIMELOG',
    VIEW_DRILLING_TICKET = 'VIEW_DRILLING_TICKET',
    EDIT_DRILLING_TICKET = 'EDIT_DRILLING_TICKET',
    VIEW_COMPLETION_FRACK_TICKET = 'VIEW_COMPLETION_FRACK_TICKET',
    EDIT_COMPLETION_FRACK_TICKET = 'EDIT_COMPLETION_FRACK_TICKET',
    VIEW_SERVICE_JOB = 'VIEW_SERVICE_JOB',
    EDIT_SERVICE_JOB = 'EDIT_SERVICE_JOB',
    VIEW_DRILLING_PROGRAM = 'VIEW_DRILLING_PROGRAM',
    EDIT_DRILLING_PROGRAM = 'EDIT_DRILLING_PROGRAM',
    APPROVE_DRILLING_PROGRAM = 'APPROVE_DRILLING_PROGRAM',
    SUBMIT_DRILLING_PROGRAM = 'SUBMIT_DRILLING_PROGRAM',
    EDIT_GEOPROGNOSIS_PRESSURE = 'EDIT_GEOPROGNOSIS_PRESSURE',
    EDIT_GEOPROGNOSIS_TEMPERATURE = 'EDIT_GEOPROGNOSIS_TEMPERATURE',
    IMPORT_DRILLING_TIMELOG = 'IMPORT_DRILLING_TIMELOG',
    IMPORT_COMPLETION_TIMELOG = 'IMPORT_COMPLETION_TIMELOG',
    VIEW_MUD_PROGRAM = 'VIEW_MUD_PROGRAM',
    EDIT_MUD_PROGRAM = 'EDIT_MUD_PROGRAM',
    VIEW_TIME_COST_ESTIMATE = 'VIEW_TIME_COST_ESTIMATE',
    EDIT_TIME_COST_ESTIMATE = 'EDIT_TIME_COST_ESTIMATE',
    VIEW_COST_ESTIMATE = 'VIEW_COST_ESTIMATE',
    EDIT_DRILLING_PHASES = 'EDIT_DRILLING_PHASES'
}

export enum KEYCODE {
    ENTER = 'Enter',
    ESCAPE = 'Escape',
    BACKSPACE = 'Backspace',
}

export const SURVEY_TYPE = new Enum([
    {id: 0, name: 'PLAN', label: 'Plan'},
    {id: 1, name: 'FACT', label: 'Survey'},
])

export enum SURVEY_TYPE_ENUM {
    PLAN = 'PLAN',
    FACT = 'FACT'
}

export enum LOG_ORIENTATION {
    VERTICAL = 'VERTICAL',
    HORIZONTAL = 'HORIZONTAL'
}

export enum LOG_TYPE {
    DEPTH = 'DEPTH',
    TIME = 'TIME'
}

export enum DEPTH_TYPE {
    MD = 'MD',
    TVD = 'TVD'
}

export enum AXIS_REFERENCE_TYPE {
    MD = 'MD',
    TVD = 'TVD',
    TIME = 'TIME'
}

export enum SYNC_TYPE {
    BY_DEPTH = 'BY_DEPTH',
    BY_TVD = 'BY_TVD',
    BY_VSEC = 'BY_VSEC',
    BY_DATE = 'BY_DATE'
}

export enum PRINT_ORIENTATION {
    PORTRAIT = 'PORTRAIT',
    LANDSCAPE = 'LANDSCAPE'
}

export enum PRINT_SIZE {
    A4 = 'A4',
    LETTER = 'LETTER'
}

export enum GEOSTEERING_X_TYPE {
    MD = 'MD',
    VSEC = 'VSEC'
}

export enum GEOSTEERING_Y_TYPE {
    TVD = 'TVD',
    TVT = 'TVT'
}

export enum TRACK_TYPE {
    HORIZONTAL = 'horizontalTracks',
    VERTICAL = 'verticalTracks',
}

export const DayOfWeekTypes = {
    MONDAY: {
        value: 'MONDAY',
        text: 'Monday',
    },
    TUESDAY: {
        value: 'TUESDAY',
        text: 'Tuesday',
    },
    WEDNESDAY: {
        value: 'WEDNESDAY',
        text: 'Wednesday',
    },
    THURSDAY: {
        value: 'THURSDAY',
        text: 'Thursday',
    },
    FRIDAY: {
        value: 'FRIDAY',
        text: 'Friday',
    },
    SATURDAY: {
        value: 'SATURDAY',
        text: 'Saturday',
    },
    SUNDAY: {
        value: 'SUNDAY',
        text: 'Sunday',
    },
} as const

export const ReportSubscriptionTypes = {
    BI_REPORT: {
        value: 'BI_REPORT',
        text: 'Multi-Well Report',
        info: 'Multi-Well Report',
        group: 'AI Reports',
    },
    WI_REPORT: {
        value: 'WI_REPORT',
        text: 'Single Well Report',
        info: 'Single Well Report',
        group: 'AI Reports',
        disabled: true,
    },
} as const

export const ReportSubscriptionPeriodTypes = {
    DAILY: {
        value: 'DAILY',
        text: 'Daily',
        remark: 'at 06:00AM',
    },
    WEEKLY: {
        value: 'WEEKLY',
        text: 'Weekly',
        remark: (dayOfWeek: string = 'Monday') => `every ${dayOfWeek} at 06:00AM`,
    },
} as const

export const AlertTypes = {
    AC_SUMMARY: {
        value: 'AC_SUMMARY',
        text: 'AC Alert',
        info: 'AC Alert based on the main DD Projection selected for the well',
        group: 'Directional Alerts'
    },
    DEVIATION_FROM_PLAN: {
        value: 'DEVIATION_FROM_PLAN',
        text: 'Deviation Alert',
        info: 'Deviation from Reference Alert based on actual surveys and reference design',
        group: 'Directional Alerts'
    },
    HARD_LINE: {
        value: 'HARD_LINE',
        text: 'Hard Line Alert',
        info: 'Hard Line Alert based on the AI Surveyed Trajectory',
        group: 'Directional Alerts'
    },
    HIGH_DLS: {
        value: 'HIGH_DLS',
        text: 'High DLS Alert',
        info: 'High DLS Alert based on the AI Surveyed Trajectory',
        group: 'Directional Alerts'
    },
    LOW_DLS: {
        value: 'LOW_DLS',
        text: 'Low DLS Alert',
        info: 'Low DLS Alert based on the AI Surveyed Trajectory',
        group: 'Directional Alerts'
    },
    NEW_SURVEY: {
        value: 'NEW_SURVEY',
        text: 'New Survey Alert',
        info: 'New Survey Alert based on the AI Surveyed Trajectory',
        group: 'Directional Alerts'
    },
    TARGET_LINE_CHANGED: {
        value: 'TARGET_LINE_CHANGED',
        text: 'Target Line Change Alert',
        info: 'Target Line Change Alert based on the AI Target Line',
        group: 'Directional Alerts'
    },
    DEFINITIVE_PLAN_CHANGED: {
        value: 'DEFINITIVE_PLAN_CHANGED',
        text: 'Plan Update Alert',
        info: 'Plan Update Alert based on the AI Definitive Plan',
        group: 'Directional Alerts'
    },
    MOTOR_STALL_DETECTED: {
        value: 'MOTOR_STALL_DETECTED',
        text: 'Motor Stall Alert',
        info: 'Motor Stall Alert based on proprietary AI Driller detection',
        group: 'Drilling Alerts'
    },
    WASHOUT_DETECTED: {
        value: 'WASHOUT_DETECTED',
        text: 'Washout Alert',
        info: 'Washout Alert based on proprietary AI Driller detection',
        group: 'Drilling Alerts'
    },
    LOW_ROP: {
        value: 'LOW_ROP',
        text: 'Low ROP Alert',
        info: 'Low ROP Alert based on proprietary AI Driller SlideSheet',
        group: 'Drilling Alerts'
    },
    MORNING_REPORT: {
        value: 'MORNING_REPORT',
        text: 'Morning Report',
        info: 'Daily Summary sent out at 6 am every morning',
        group: 'Reports'
    },
    CONNECTION_REPORT: {
        value: 'CONNECTION_REPORT',
        text: 'Connection Report',
        info: 'Daily Connection Report sent out at 6 am every morning',
        group: 'Reports'
    },
    // WEEKLY_REPORT: {
    //     value: 'WEEKLY_REPORT',
    //     text: 'Weekly Report',
    //     info: '',
    //     group: 'Reports'
    // },
    FILE_DRIVE: {
        value: 'FILE_DRIVE',
        text: 'File Drive Alert',
        info: 'New File uploads Alert',
        group: 'System Alerts'
    },
    NEW_RECOMMENDATION: {
        value: 'NEW_RECOMMENDATION',
        text: 'New Recommendation Alert',
        info: 'New Recommendation Alert based on the AI Recommendations',
        group: 'System Alerts'
    },
    RULE_BASED: {
        value: 'RULE_BASED',
        text: 'Custom Alert',
        info: 'Custom Alert',
        group: 'System Alerts'
    }
} as const
export const AlertDeliveryTypes = {
    SUMMARY: {
        value: 'SUMMARY',
        text: 'Summary',
        icon: 'mdi-monitor',
        order: 1,
    },
    EMAIL: {
        value: 'EMAIL',
        text: 'Email',
        icon: 'mdi-email-outline',
        order: 2,
    },
    SMS: {
        value: 'SMS',
        text: 'SMS',
        icon: 'mdi-cellphone-message',
        order: 3,
    }
} as const

export const WarningAlertLevelTypes = {
    WARNING: {
        value: 'WARNING',
        text: 'Warning',
        icon: 'mdi-alert-outline',
        iconColor: 'warning'
    },
    CRITICAL: {
        value: 'CRITICAL',
        text: 'Critical',
        icon: 'mdi-alert-octagon-outline',
        iconColor: 'error'
    }
} as const

export const AlertLevelTypes = {
    INFO: {
        value: 'INFO',
        text: 'Info',
        icon: 'mdi-alert-circle-outline',
        iconColor: 'info',
    },
    ...WarningAlertLevelTypes
} as const

export const ExtAlertLevelCell = {
    OK: {
        value: 'OK',
        text: 'Ok',
        icon: 'mdi-check',
        iconColor: 'success',
    },
    ...AlertLevelTypes,
} as const

export const WELLBORE_SECTION_TYPE = new Enum([
    {name: 'RISER', label: 'Riser', disabled: true},
    {name: 'CONDUCTOR', label: 'Conductor'},
    {name: 'SURFACE', label: 'Surface'},
    {name: 'INTERMEDIATE', label: 'Intermediate'},
    {name: 'PRODUCTION', label: 'Production'},
    {name: 'UNKNOWN', label: 'Unknown'},
])

export enum FILE_DRIVE_FOLDER_ID {
    DRILLING = 0,
    COMPLETIONS = 1,
}

export enum WELLBORE_SEGMENT_TYPE {
    CASING = 'CASING',
    OPEN_HOLE = 'OPEN_HOLE',
    CEMENTING = 'CEMENTING',
    UNKNOWN = 'UNKNOWN',
}

export enum CEMENTING_STAGE_TYPE {
    LEAD = 'LEAD',
    TAIL = 'TAIL',
    UNKNOWN = 'UNKNOWN',
}

export const RTAxisTypes = {
    MD: {
        value: 'md',
        text: 'MD'
    },
    INC: {
        value: 'inc',
        text: 'Inc'
    },
    AZM: {
        value: 'az',
        text: 'Azm'
    },
    VS: {
        value: 'vsec',
        text: 'VS'
    },
    TVD: {
        value: 'tvd',
        text: 'TVD'
    },
    DIFF_P: {
        value: 'diff_pressure',
        text: 'Diff P'
    },
    GAMMA: {
        value: 'gamma',
        text: 'Gamma'
    },
    WOB: {
        value: 'wob',
        text: 'WOB'
    },
    RPM: {
        value: 'rpm',
        text: 'SRPM'
    },
    DLS: {
        value: 'dls',
        text: 'DLS'
    },
    TOOLFACE: {
        value: 'tf',
        text: 'Toolface'
    },
    N_S: {
        value: 'ns_from_ref',
        text: 'N(+)/S(-)'
    },
    E_W: {
        value: 'ew_from_ref',
        text: 'E(+)/W(-)'
    }
}

export const VIEW_LIST = {
    CHART: {
        value: 'CHART',
        text: 'Chart'
    },
    TABLE: {
        value: 'TABLE',
        text: 'Table'
    }
} as const

export const VALUE_LOADING = Symbol('VALUE_LOADING')

export enum MOTOR_YIELD_METHODS {
    MANUAL = 'MANUAL',
    LAST_ONE = 'LAST_ONE',
    TREND = 'TREND'
}

export enum ROTARY_WALK_METHODS {
    MANUAL = 'MANUAL',
    LAST_ONE = 'LAST_ONE',
    LAST_TWO = 'LAST_TWO',
    LAST_THREE = 'LAST_THREE'
}
export enum SURVEY_OFFSET_METHODS {
    MANUAL = 'MANUAL',
    BHA_BASED = 'BHA_BASED',
}

export type TProjectionMethods =
    'SMART_TO_BIT' |
    'SMART_TO_CL' |
    'LIVE_SMART_TO_BIT' |
    'LIVE_SMART_AZM_TO_BIT' |
    'RSS_LIVE_SMART_TO_BIT' |
    'RSS_LIVE_SMART_AZM_TO_BIT' |
    'STRAIGHT_LINE_TO_BIT' |
    'STRAIGHT_LINE_TO_CL' |
    'STRAIGHT_LINE_TO_TVD' |
    'STRAIGHT_LINE_TO_VSEC' |
    'LAST_DLS_TF' |
    'TREND_DLS_TF' |
    'MANUAL_CL_INC_AZM' |
    'MANUAL_MD_INC_AZM' |
    'INC_AZM_TO_BIT' |
    'MANUAL_TVD_INC_AZM' |
    'MANUAL_CL_DLS_TF' |
    'DLS_TF_TO_BIT' |
    'MANUAL_TVD_DLS_TF'

export const PROJECTION_METHODS: Record<TProjectionMethods, IProjectionMethod> = {
    SMART_TO_BIT: {
        text: "Smart Projection to Bit",
        shortLabel: "Smart to Bit",
        value: 'SMART_TO_BIT',
        is_smart: true,
        description:
            "This method will calculate a Smart Projection to the Bit in Real-Time based on expected BHA performance.<br><br>Please enter below your expected Motor Yield, Rotary Walk Rate, Rotary Walk Direction.",
    },
    SMART_TO_CL: {
        text: "Smart Projection to CL",
        shortLabel: "Smart to CL",
        value: 'SMART_TO_CL',
        is_smart: true,
        description:
            "This method will calculate a Smart Projection in Real-Time based on expected BHA performance.<br><br>Please enter below your desired Course Length (CL) and expected Motor Yield, Rotary Walk Rate, Rotary Walk Direction.",
    },
    LIVE_SMART_TO_BIT: {
        text: "MWD Live Projection to Bit",
        shortLabel: "MWD Live to Bit",
        value: 'LIVE_SMART_TO_BIT',
        is_smart: true,
        description:
            "This method will calculate Projection to the Bit in Real-Time based on MWD Continuous Inclination and expected BHA performance.<br><br>Please enter below your actual MWD Survey Offset and expected Motor Yield, Rotary Walk Rate, Rotary Walk Direction.",
    },
    LIVE_SMART_AZM_TO_BIT: {
        text: "MWD Live+Smart Azm to Bit",
        shortLabel: "MWD Live+Smart Azm to Bit",
        value: 'LIVE_SMART_AZM_TO_BIT',
        is_smart: true,
        description:
            "This method will calculate Projection to the Bit based on Continuous Inclination from MWD and expected Azimuth change based on Continuous Inclination multiplied by Rotary Walk Ratio. For example, if Inclination has increased by 1° and Walk Ratio is 2, Azimuth will change to the Right by 2°.<br><br>Please enter below your actual MWD Survey Offset, expected Motor Yield, and Rotary Walk Ratio.",
    },
    RSS_LIVE_SMART_TO_BIT: {
        text: "RSS Live Projection to Bit",
        shortLabel: "RSS Live to Bit",
        value: 'RSS_LIVE_SMART_TO_BIT',
        is_smart: true,
        description:
            "This method will calculate Projection to the Bit in Real-Time based on RSS Continuous Inclination and expected BHA performance.<br><br>Please enter below your actual RSS Survey Offset and expected Motor Yield, Rotary Walk Rate, Rotary Walk Direction.",
    },
    RSS_LIVE_SMART_AZM_TO_BIT: {
        text: "RSS Live+Smart Azm to Bit",
        shortLabel: "RSS Live+Smart Azm to Bit",
        value: 'RSS_LIVE_SMART_AZM_TO_BIT',
        is_smart: true,
        description:
            "This method will calculate Projection to the Bit based on Continuous Inclination from RSS and expected Azimuth change based on Continuous Inclination multiplied by Rotary Walk Ratio. For example, if Inclination has increased by 1° and Walk Ratio is 2, Azimuth will change to the Right by 2°.<br><br>Please enter below your actual RSS Survey Offset, expected Motor Yield, and Rotary Walk Ratio.",
    },
    STRAIGHT_LINE_TO_BIT: {
        text: "Straight Line to Bit",
        shortLabel: "Straight to Bit",
        value: 'STRAIGHT_LINE_TO_BIT',
        is_smart: true,
        description:
            "This method will calculate a Projection to the Bit with the same Inclination and Azimuth values as in Survey Tie-In.",
    },
    STRAIGHT_LINE_TO_CL: {
        text: "Straight Line to CL",
        shortLabel: "Straight to CL",
        value: 'STRAIGHT_LINE_TO_CL',
        is_smart: false,
        description:
            "This method will calculate a Projection with the same Inclination and Azimuth values as in Survey Tie-In.<br><br>Please enter below your desired Course Length (CL).",
    },
    STRAIGHT_LINE_TO_TVD: {
        text: "Straight Line to TVD",
        shortLabel: "Straight to TVD",
        value: 'STRAIGHT_LINE_TO_TVD',
        is_smart: false,
        description:
            "This method will calculate a Projection with the same Inclination and Azimuth values as in Survey Tie-In.<br><br>Please enter below your desired TVD.",
    },
    STRAIGHT_LINE_TO_VSEC: {
        text: "Straight Line to VS",
        shortLabel: "Straight to VS",
        value: 'STRAIGHT_LINE_TO_VSEC',
        is_smart: false,
        description:
            "This method will calculate a Projection with the same Inclination and Azimuth values as in Survey Tie-In.<br><br>Please enter below your desired V.Sec.",
    },
    LAST_DLS_TF: {
        text: "Last DLS/TF",
        shortLabel: "Last DLS/TF",
        value: 'LAST_DLS_TF',
        is_smart: false,
        description:
            "This method will calculate a Projection with the same average Dogleg Severity and Toolface values as in Survey Tie-In.<br><br>Please enter below your desired Course Length (CL).",
    },
    TREND_DLS_TF: {
        text: "Trend DLS/TF",
        shortLabel: "Trend DLS/TF",
        value: 'TREND_DLS_TF',
        is_smart: false,
        description:
            "This method will calculate a Projection with the average Dogleg Severity and Toolface values based on the last 4 surveys including Survey Tie-In.<br><br>Please enter below your desired Course Length (CL).",
    },
    MANUAL_CL_INC_AZM: {
        text: "CL/INC/AZM",
        shortLabel: "CL/INC/AZM",
        value: 'MANUAL_CL_INC_AZM',
        is_smart: false,
        description:
            "This method will calculate a Projection to a specific Inclination and Azimuth.<br><br>Please enter below your desired Course Length (CL), Inclination (INC), and Azimuth (AZM).",
    },
    MANUAL_MD_INC_AZM: {
        text: "MD/INC/AZM",
        shortLabel: "MD/INC/AZM",
        value: 'MANUAL_MD_INC_AZM',
        is_smart: false,
        description:
            "This method will calculate a Projection to a specific Measured Depth, Inclination, and Azimuth.<br><br>Please enter below your desired Measured Depth (MD), Inclination (INC), and Azimuth (AZM).",
    },
    INC_AZM_TO_BIT: {
        text: "INC/AZM to Bit",
        shortLabel: "INC/AZM to Bit",
        value: 'INC_AZM_TO_BIT',
        is_smart: true,
        description:
            "This method will calculate a Projection to the Bit based on expected Inclination and Azimuth.<br><br>Please enter below your desired Inclination (INC) and Azimuth (AZM).",
    },
    MANUAL_TVD_INC_AZM: {
        text: "TVD/INC/AZM",
        shortLabel: "TVD/INC/AZM",
        value: 'MANUAL_TVD_INC_AZM',
        is_smart: false,
        description:
            "This method will calculate a Projection to a specific TVD, Inclination and Azimuth.<br><br>Please enter below your desired True Vertical Depth (TVD), Inclination (INC), and Azimuth (AZM).",
    },
    MANUAL_CL_DLS_TF: {
        text: "CL/DLS/TF",
        shortLabel: "CL/DLS/TF",
        value: 'MANUAL_CL_DLS_TF',
        is_smart: false,
        description:
            "This method will calculate a Projection with a specific Dogleg Severity and Toolface.<br><br>Please enter below your desired Course Length (CL), Dogleg Severity (DLS), and Toolface (TF).",
    },
    DLS_TF_TO_BIT: {
        text: "DLS/TF to Bit",
        shortLabel: "DLS/TF to Bit",
        value: 'DLS_TF_TO_BIT',
        is_smart: true,
        description:
            "This method will calculate a Projection to the Bit based on specific Dogleg Severity and Toolface.<br><br>Please enter below your desired Dogleg Severity (DLS) and Toolface (TF).",
    },
    MANUAL_TVD_DLS_TF: {
        text: "TVD/DLS/TF",
        shortLabel: "TVD/DLS/TF",
        value: 'MANUAL_TVD_DLS_TF',
        is_smart: false,
        description:
            "This method will calculate a Projection to a desired TVD with specific Dogleg Severity and Toolface.<br><br>Please enter below your desired True Vertical Depth (TVD), Dogleg Severity (DLS), and Toolface (TF).",
    },
} as const

export const WELL_STATUSES = {
    active: {id: 1, name: 'active', title: 'Active', color: '#4CAF50'},
    paused: {id: 2, name: 'paused', title: 'Paused', color: CRIMSON},
    planned: {id: 4, name: 'planned', title: 'Planned', color: ORANGE},
    completed: {id: 3, name: 'completed', title: 'Completed', color: 'grey'}
}

export const PRViewList = {
    PLAN_VIEW: {
        text: 'Plan View',
        value: 'plan_view'
    },
    VERTICAL_SECTION: {
        text: 'Vertical Section',
        value: 'vertical_section'
    }
}
export const ToolfaceSource = {
    GTF: {
        text: 'GTF',
        value: 'GTF'
    },
    MTF: {
        text: 'MTF',
        value: 'MTF'
    }
}

export const MULTI_WELL_AUTO_UPDATE_TYPE = {
    EVERY_15_MINUTE: {
        text: 'Every 15 min',
        value: 'every_15_minute',
        time: 15 * MS_IN_MINUTE
    },
    EVERY_1_HOUR: {
        text: 'Every 1 hr',
        value: 'every_1_hour',
        time: MS_IN_HOUR
    },
    NONE: {
        text: 'None',
        value: 'none'
    }
}
export const AC_RISK_WELL_COUNT = {
    TOP_3: {
        value: 3,
        text: 'Top 3',
        onlyAlert: false
    },
    TOP_5: {
        value: 5,
        text: 'Top 5',
        onlyAlert: false
    },
    TOP_10: {
        value: 10,
        text: 'Top 10',
        onlyAlert: true
    }
}
export const AC_RISK_ORDER_BY = {
    CTC: {
        value: 'CTC',
        text: 'CTC'
    },
    SF: {
        value: 'SF',
        text: 'SF'
    }
}

export const TORQUE_AND_DRAG_OPERATIONS = {
    DRILLING_HOOKLOAD: {
        value: 'DRILLING_HOOKLOAD',
        text: 'Drilling - Hookload',
    },
    DRILLING_ROTARY_TORQUE: {
        value: 'DRILLING_ROTARY_TORQUE',
        text: 'Drilling Rotary - Torque',
    },
    DRILLING_STANDPIPE_PRESSURE: {
        value: 'DRILLING_STANDPIPE_PRESSURE',
        text: 'Drilling - Standpipe Pressure',
    },
    TRIPPING_PIPE_IN_HOLE_HOOKLOAD: {
        value: 'TRIPPING_PIPE_IN_HOLE_HOOKLOAD',
        text: 'Tripping Pipe In Hole - Hookload',
    },
    TRIPPING_PIPE_OUT_OF_HOLE_HOOKLOAD: {
        value: 'TRIPPING_PIPE_OUT_OF_HOLE_HOOKLOAD',
        text: 'Tripping Pipe Out of Hole - Hookload',
    },
    RUNNING_CASING_IN_HOOKLOAD: {
        value: 'RUNNING_CASING_IN_HOOKLOAD',
        text: 'Running Casing In - Hookload',
    }
}

export enum DATA_SOURCE_STAGE {
    DRILLING = 'DRILLING',
    COMPLETION_FRAC = 'COMPLETION_FRAC',
    COMPLETION_WIRELINE = 'COMPLETION_WIRELINE',
    PRODUCTION = 'PRODUCTION'
}

export enum LAYOUT_MODES {
    ADMIN = 'ADMIN', //'ADMIN'
    MULTIWELL = 'MULTIWELL', // 'MW'
    ALERTS = 'ALERTS', // 'Alerts'
    REPORTS_MANAGEMENT = 'REPORTS_MANAGEMENT', // 'RM'
    DRILLING = 'DRILLING', // 'SW'
    COMPLETIONS = 'COMPLETIONS', //'CI'
    PRODUCTION = 'PRODUCTION',
    PROJECT = 'PROJECT',
    WELL_PLANNING = 'WELL_PLANNING'
}

export enum MARKER_TYPES {
    BHA = 'BHA',
    RIG_MESSAGES = 'RIG_MESSAGES',
    NOTES = 'NOTES',
    INCIDENTS = 'INCIDENTS',
    RECOMMENDATIONS = 'RECOMMENDATIONS'
}

export const EVENT_MARKERS = {
    [MARKER_TYPES.BHA]: {
        sign: 'B',
        label: 'BHA',
        color: '#9E9E9E'
    },
    [MARKER_TYPES.NOTES]: {
        sign: 'C',
        label: 'Comment',
        color: '#1976D2'
    },
    [MARKER_TYPES.INCIDENTS]: {
        sign: 'T',
        label: 'Trouble',
        color: '#D32F2F'
    },
    [MARKER_TYPES.RECOMMENDATIONS]: {
        sign: 'R',
        label: 'Recommendation',
        color: '#FBC02D'
    },
    [MARKER_TYPES.RIG_MESSAGES]: {
        sign: 'M',
        label: 'Rig Message',
        color: '#00796B'
    },
}

export const SYNC_TYPE_MAP = {
    [AXIS_REFERENCE_TYPE.TVD]: SYNC_TYPE.BY_TVD,
    [AXIS_REFERENCE_TYPE.MD]: SYNC_TYPE.BY_DEPTH,
    [AXIS_REFERENCE_TYPE.TIME]: SYNC_TYPE.BY_DATE,
    [DEPTH_TYPE.TVD]: SYNC_TYPE.BY_TVD,
    [DEPTH_TYPE.MD]: SYNC_TYPE.BY_DEPTH,
    [GEOSTEERING_X_TYPE.VSEC]: SYNC_TYPE.BY_VSEC,
    [GEOSTEERING_X_TYPE.MD]: SYNC_TYPE.BY_DEPTH,
}

export enum MARKER_VALUE_TYPES {
    depth = 'depth',
    time = 'time'
}

export enum RIG_MARKER_VALUE_TYPES {
    hole_depth = 'hole_depth',
    tvd = 'tvd',
    time = 'time',
    vsec = 'vsec'
}

export const MARKER_VALUE_KEY_MAP = {
    [AXIS_REFERENCE_TYPE.TIME]: MARKER_VALUE_TYPES.time,
    [AXIS_REFERENCE_TYPE.MD]: MARKER_VALUE_TYPES.depth,
    [AXIS_REFERENCE_TYPE.TVD]: MARKER_VALUE_TYPES.depth,
}

export const RIG_MARKER_VALUE_KEY_MAP = {
    [AXIS_REFERENCE_TYPE.TIME]: RIG_MARKER_VALUE_TYPES.time,
    [AXIS_REFERENCE_TYPE.MD]: RIG_MARKER_VALUE_TYPES.hole_depth,
    [AXIS_REFERENCE_TYPE.TVD]: RIG_MARKER_VALUE_TYPES.tvd,
    [GEOSTEERING_X_TYPE.VSEC]: RIG_MARKER_VALUE_TYPES.vsec,
    [GEOSTEERING_X_TYPE.MD]: RIG_MARKER_VALUE_TYPES.hole_depth,
}

export enum SURVEY_VARIANT {
    PRINCIPAL = 'PRINCIPAL',
    PROPOSAL = 'PROPOSAL',
    GEOSTEERING = 'GEOSTEERING'
}

export enum PLAN_VARIANT {
    PRINCIPAL = 'PRINCIPAL',
    PROTOTYPE = 'PROTOTYPE',
}

export enum CASING_TYPES {
    NOT_APPLIED = 'NOT_APPLIED',
    ADDED_TO_ERRORS = 'ADDED_TO_ERRORS',
    SUBTRACT = 'SUBTRACT',
    TBR = 'TBR',
    ADD_AND_SUBTRACT = 'ADD_AND_SUBTRACT',
    WPTS_RULE = 'WPTS_RULE',
}

export const CASING_TYPES_PARAMS: Array<{value: CASING_TYPES, text: string }> = [
    {
        value: CASING_TYPES.NOT_APPLIED,
        text: 'Not Applied',
    },
    {
        value: CASING_TYPES.ADDED_TO_ERRORS,
        text: 'Added to Errors',
    },
    {
        value: CASING_TYPES.SUBTRACT,
        text: 'Subtract from CC',
    },
    {
        value: CASING_TYPES.TBR,
        text: 'TBR Borehole Radius',
    },
    {
        value: CASING_TYPES.ADD_AND_SUBTRACT,
        text: 'Add and Subtract',
    },
    {
        value: CASING_TYPES.WPTS_RULE,
        text: 'WPTS Rule',
    },
]

export enum TOOLFACE_SOURCE {
    GTF = 'GTF',
    MTF = 'MTF',
}

export const TOOLFACE_SOURCE_COLORS = {
    [TOOLFACE_SOURCE.GTF]: 'inherit',
    [TOOLFACE_SOURCE.MTF]: FONT_BLUE,
}

export enum VALIDATION_TYPES {
    SETTINGS = 'SETTINGS',
    COMMON = 'COMMON',
    BHA = 'BHA',
    CASING = 'CASING',
    DESIGN = 'DESIGN',
    WELL = 'WELL',
    WELL_DATASOURCE = 'WELL_DATASOURCE'
}
export enum EDM_ENTITY_TYPE {
    PROJECT = 'project',
    WELL = 'well'
}

export enum EDM_OBJECT_TYPE {
    POLICY = 'POLICY',
    PROJECT = 'PROJECT',
    SITE = 'SITE',
    WELL = 'WELL',
    WELLBORE = 'WELLBORE',
    SURVEY = 'SURVEY',
    SURVEY_SOURCE = 'SURVEY_SOURCE'
}

export enum SCENARIO_BREADCRUMBS_DIALOG_TYPE {
    CASING = 'CASING',
    BHA = 'BHA',
    DESIGN = 'DESIGN',
    TRAJECTORY = 'TRAJECTORY'
}

export enum EVENTS_SCOPE_TYPE {
    ADMIN = 'ADMIN',
    USER = 'USER',
}

export enum INTERPOLATE_TYPES {
    MANUAL = 'MANUAL',
    AUTO = 'AUTO',
}

export enum PERIOD_SOURCE_TYPES {
    buttonToday = 'buttonToday',
    buttonYesterday = 'buttonYesterday',
    buttonWell = 'buttonWell',
    filter = 'filter',
    section = 'section',
    run = 'run',
    realtime = 'realtime',
}

export enum DATA_FILTER_DRILLING_TYPES {
    START_DRILLING = 'START_DRILLING',
    STOP_DRILLING = 'STOP_DRILLING',
    START_TRIPPING = 'START_TRIPPING',
    STOP_TRIPPING = 'STOP_TRIPPING',
}

export const DATA_FILTER_DRILLING_LIST  = {
    [DATA_FILTER_DRILLING_TYPES.START_DRILLING]: {
        text: 'First Drilling',
        value: DATA_FILTER_DRILLING_TYPES.START_DRILLING
    },
    [DATA_FILTER_DRILLING_TYPES.STOP_DRILLING]: {
        text: 'Last Drilling',
        value: DATA_FILTER_DRILLING_TYPES.STOP_DRILLING
    },
    [DATA_FILTER_DRILLING_TYPES.START_TRIPPING]: {
        text: 'First Tripping',
        value: DATA_FILTER_DRILLING_TYPES.START_TRIPPING
    },
    [DATA_FILTER_DRILLING_TYPES.STOP_TRIPPING]: {
        text: 'Last Tripping',
        value: DATA_FILTER_DRILLING_TYPES.STOP_TRIPPING
    }
}

export enum SAVED_SEARCH_KEYS {
    ADMIN_USERS = 'admin_users',
    ADMIN_COMPANIES = 'admin_companies',
    ADMIN_WELLS = 'admin_wells',
    ADMIN_PROJECTS = 'admin_projets'
}

export enum LOG_TOOLTIP_MODE {
    SHORT = 'short',
    STANDARD = 'standard',
    FIXED = 'fixed',
}

export const LOG_TOOLTIP_MODE_LIST = [
    {
        text: 'Large',
        value: LOG_TOOLTIP_MODE.STANDARD,
        icon: 'mdi-size-l'
    },
    {
        text: 'Small',
        value: LOG_TOOLTIP_MODE.SHORT,
        icon: 'mdi-size-s'
    },
    {
        text: 'Fixed',
        value: LOG_TOOLTIP_MODE.FIXED,
        icon: 'mdi-alpha-f'
    },
]

export enum AC_WARNING_LEVEL_TYPES {
    SF = 'SF',
    CTC = 'CTC',
    DBE = 'DBE',
}

export enum EDM_TARGETS_TYPES {
    NONE = 'NONE',
    SURVEY = 'SURVEY',
    WELLBORE = 'WELLBORE',
    WELL = 'WELL',
    SITE = 'SITE'
}

export enum EDM_BOUNDARIES_TYPES {
    NONE = 'NONE',
    WELL = 'WELL',
    SITE = 'SITE'
}
