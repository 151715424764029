interface WidgetEntry {
    label: string
    icon?: string
    import: () => Promise<{default: any}>
}

export const TABLE_ICON = 'mdi-table'
export const CHART_ICON = 'mdi-chart-box-outline'
export const WIDGET_ICON = 'mdi-widgets-outline'

export const enum Widget {
    AcSummaryTable = 'AcSummaryTable',
    AcPerWellTable = 'AcPerWellTable',
    AcLookaheadTable = 'AcLookaheadTable',
    AcRiskTable = 'AcRiskTable',
    ActivityStatTable = 'ActivityStatTable',
    BackToPlanTable = 'BackToPlanTable',
    ConnectionStatTable = 'ConnectionStatTable',
    DrillingStatTable = 'DrillingStatTable',
    EdmViewer = 'EdmViewer',
    PlanTable = 'PlanTable',
    ProjectionsTable = 'ProjectionsTable',
    PlannedTrajectoriesTable = 'PlannedTrajectoriesTable',
    RotaryStatTable = 'RotaryStatTable',
    SlideSheetTable = 'SlideSheetTable',
    TimeLogTable = 'TimeLogTable',
    DailyCostsTable = 'DailyCostsTable',
    TimeJournalTable = 'TimeJournalTable',
    CompletionsTimeJournalTable = 'CompletionsTimeJournalTable',
    SlideStatTable = 'SlideStatTable',
    SurveyTable = 'SurveyTable',
    WellRunSheetTable = 'WellRunSheetTable',
    AzimuthAnalysisChart = 'AzimuthAnalysisChart',
    ConnectionsTimeChart = 'ConnectionsTimeChart',
    ConnectionsDistributionChart = 'ConnectionsDistributionChart',
    CumulativeTortuosityChart = 'CumulativeTortuosityChart',
    DaysVsDepthSingleWellChart = 'DaysVsDepthChart',
    CostVsDepthChart = 'CostVsDepthChart',
    DaysVsDepthMultiWellChart = 'DaysVsDepthMultiWellChart',
    DlsVarianceChart = 'DlsVarianceChart',
    DrillingActivitiesByDayChart = 'DrillingActivitiesByDayChart',
    DrillingActivitiesTotalChart = 'DrillingActivitiesTotalChart',
    DrillingDistanceChart = 'DrillingDistanceChart',
    DrillingROPChart = 'DrillingROPChart',
    DrillingTimeChart = 'DrillingTimeChart',
    InclinationAnalysisChart = 'InclinationAnalysisChart',
    PlanViewChart = 'PlanViewChart',
    CompletionsPlanViewChart = 'CompletionsPlanViewChart',
    ProximityToPlanChart = 'ProximityToPlanChart',
    Pva3dChart = 'Pva3dChart',
    CompletionsPva3dChart = 'CompletionsPva3dChart',
    RoadmapTable = 'RoadmapTable',
    RotaryBuildwalkChart = 'RotaryBuildwalkChart',
    RotaryDrillingChart = 'RotaryDrillingChart',
    RotaryTendencyChart = 'RotaryTendencyChart',
    SeparationFromPlanChart = 'SeparationFromPlanChart',
    SlideDrillingChart = 'SlideDrillingChart',
    TvdAnalysisChart = 'TvdAnalysisChart',
    VerticalSectionChart = 'VerticalSectionChart',
    CompletionsVerticalSectionChart = 'CompletionsVerticalSectionChart',
    DrillingControlLog = 'ControlLog',
    CompletionsControlLog = 'CompletionsControlLog',
    CompletionsCustomLog = 'CompletionsCustomLog',
    CompletionsCustomLogWidget = 'CompletionsCustomLogWidget',
    CompletionsCustomLogWidgetProjectIntelligence = 'CompletionsCustomLogWidgetProjectIntelligence',
    DrillingCustomLog = 'CustomLog',
    DrillingCustomLogWidget = 'CustomLogWidget',
    DrillingCustomLogWidgetProjectIntelligence = 'DrillingCustomLogWidgetProjectIntelligence',
    DepthComparisonLog = 'DepthComparisonLog',
    DepthComparisonLogWidget = 'DepthComparisonLogWidget',
    TimeAnalysisLog = 'TimeAnalysisLog',
    DrillingTimeAnalysisLogWidget = 'DrillingTimeAnalysisLogWidget',
    CompletionsTreatmentPlot = 'CompletionsTreatmentPlot',
    CompletionsTreatmentPlotWidget = 'CompletionsTreatmentPlotWidget',
    ChannelSolidGauge = 'ChannelSolidGauge',
    DataGrid = 'DataGrid',
    MultiTabs = 'MultiTabs',
    SWCMultiTabs = 'SWCMultiTabs',
    MWDMultiTabs = 'MWDMultiTabs',
    MWCMultiTabs = 'MWCMultiTabs',
    SingleWellWidgetWrapper = 'SingleWellWidgetWrapper',
    WidgetsLayout = 'WidgetsLayout',
    MultiWellDrillingWidgetsLayout = 'MultiWellWidgetsLayout',
    ProjectWidgetsLayout = 'ProjectWidgetsLayout',
    MultiWellCompletionsWidgetsLayout = 'MultiWellCompletionsWidgetsLayout',
    CompletionsWidgetsLayout = 'CompletionsWidgetsLayout',
    ReportChart = 'ReportChart',
    RunDistributionReport = 'RunDistributionReport',
    StageDistributionReport = 'StageDistributionReport',
    MultiStatisticReport = 'MultiStatisticReport',
    TrippStatTable = 'TrippStatTable',
    TrippingConnectionsAnalysisChart = 'TrippingConnectionsAnalysisChart',
    TrippingDistanceChart = 'TrippingDistanceChart',
    TrippingSpeedAnalysisChart = 'TrippingSpeedAnalysisChart',
    TrippingSpeedChart = 'TrippingSpeedChart',
    TrippingTimeChart = 'TrippingTimeChart',
    ToolFaceChart = 'ToolFaceChart',
    WellsMap = 'WellsMap',
    WellMapWidthEdm = 'WellMapWidthEdm',
    CompletionsWellMapWidthEdm = 'CompletionsWellMapWidthEdm',
    ZeroWobChart = 'ZeroWobChart',
    ZeroDiffChart = 'ZeroDiffChart',
    Geosteering = 'Geosteering',
    DrillingGeosteeringWidget = 'DrillingGeosteeringWidget',
    CompletionsGeosteeringWidget = 'CompletionsGeosteeringWidget',
    StatisticsHub = 'StatisticsHub',
    BiRunSummary = 'BiRunSummary',
    BiStageSummary = 'BiStageSummary',
    MudReportsTable = 'MudReportsTable',
    MudProgramTable = 'MudProgramTable',
    TrippingLog = 'TrippingLog',
    ScatterPlot = 'ScatterPlot',
    SchematicDesignChart = 'SchematicDesignChart',
    DesignDetailsView = 'DesignDetailsView',

    CorrectionsTable = 'CorrectionsTable',
    ManualActivitiesTable = 'ManualActivitiesTable',
    StandCorrectionsTable = 'StandCorrectionsTable',
    DrillingZonesTable = 'ZonesTable',
    CompletionZonesTable = 'CompletionZonesTable',
    WellsAuditTable = 'WellsAuditTable',
    RoadmapConfiguration = 'RoadmapConfiguration',
    RotaryProjectionsChart = 'RotaryProjectionsChart',
    EventCorrectionsTable = 'EventCorrectionsTable',

    IntervalSummaryTable = 'IntervalSummaryTable',
    ScenarioEditor = 'ScenarioEditor',
    DrillingProgram = 'DrillingProgram',

    DrillingTroubleSummaryTable = 'DrillingTroubleSummaryTable',
    CompletionsTroubleSummaryTable = 'CompletionsTroubleSummaryTable',

    MultiWellDrillingTroubleSummaryTable = 'MultiWellDrillingTroubleSummaryTable',
    MultiWellCompletionsTroubleSummaryTable = 'MultiWellCompletionsTroubleSummaryTable',


    MultiWellDrillingRecommendationSummaryTable = 'MultiWellDrillingRecommendationSummaryTable',
    MultiWellCompletionsRecommendationSummaryTable = 'MultiWellCompletionsRecommendationSummaryTable',

    DrillingRecommendationSummaryTable = 'DrillingRecommendationSummaryTable',
    CompletionsRecommendationSummaryTable = 'CompletionsRecommendationSummaryTable',

    SurveyNodeWidget = 'SurveyNodeWidget',
    EdmPlanViewChart = 'EdmPlanViewChart',
    EdmVerticalSectionChart = 'EdmVerticalSectionChart',
    EdmPva3dChart = 'EdmPva3dChart',
    EdmAcPerWellTable = 'EdmAcPerWellTable',
    EdmAcSummaryTable = 'EdmAcSummaryTable',

    Notes = 'Template Notes',
}

export const widgetsDictionary: Record<Widget, WidgetEntry> = {
    [Widget.AcSummaryTable]: {
        import: () => import('@/models/widgets/tables/AntiCollision/AcSummaryTable'),
        label: 'AC Summary',
        icon: TABLE_ICON,
    },
    [Widget.AcPerWellTable]: {
        import: () => import('@/models/widgets/tables/AntiCollision/AcPerWellTable'),
        label: 'AC per Well',
        icon: TABLE_ICON,
    },
    [Widget.AcLookaheadTable]: {
        import: () => import('@/models/widgets/tables/AntiCollision/AcLookaheadTable'),
        label: 'AC Lookahead',
        icon: TABLE_ICON,
    },
    [Widget.AcRiskTable]: {
        import: () => import('@/models/widgets/tables/AntiCollision/AcRiskTable'),
        label: 'AC Risk',
        icon: TABLE_ICON,
    },
    [Widget.ActivityStatTable]: {
        import: () => import('@/models/widgets/tables/ActivityStatTable'),
        label: 'Activity Statistics',
        icon: TABLE_ICON,
    },
    [Widget.BackToPlanTable]: {
        import: () => import('@/models/widgets/tables/BackToPlanTable'),
        label: 'Quick Plan',
        icon: TABLE_ICON,
    },
    [Widget.ConnectionStatTable]: {
        import: () => import('@/models/widgets/tables/ConnectionStatTable'),
        label: 'Weight to Weight Statistics',
        icon: TABLE_ICON,
    },
    [Widget.DrillingStatTable]: {
        import: () => import('@/models/widgets/tables/DrillingStatTable'),
        label: 'Drilling Statistics',
        icon: TABLE_ICON,
    },
    [Widget.EdmViewer]: {
        import: () => import('@/models/widgets/tables/EdmViewer'),
        label: 'EDM Viewer',
        icon: TABLE_ICON,
    },
    [Widget.PlanTable]: {
        import: () => import('@/models/widgets/tables/SurveyPointsTables/PlanTable'),
        label: 'Definitive Plan',
        icon: TABLE_ICON,
    },
    [Widget.ProjectionsTable]: {
        import: () => import('@/models/widgets/tables/ProjectionsTable'),
        label: 'Projections',
        icon: TABLE_ICON,
    },
    [Widget.RoadmapTable]: {
        import: () => import('@/models/widgets/tables/RoadmapTable'),
        label: 'Roadmap Summary',
        icon: TABLE_ICON,
    },
    [Widget.RotaryStatTable]: {
        import: () => import('@/models/widgets/tables/RotaryStatTable'),
        label: 'Rotary Statistics',
        icon: TABLE_ICON,
    },
    [Widget.SlideSheetTable]: {
        import: () => import('@/models/widgets/tables/SlideSheetTable'),
        label: 'Slidesheet',
        icon: TABLE_ICON,
    },
    [Widget.TimeLogTable]: {
        import: () => import('@/models/widgets/tables/TimeLogTable'),
        label: 'Auto-Time Log',
        icon: TABLE_ICON,
    },
    [Widget.DailyCostsTable]: {
        import: () => import('@/models/widgets/tables/DailyCostsTable'),
        label: 'Daily Cost',
        icon: TABLE_ICON,
    },
    [Widget.TimeJournalTable]: {
        import: () => import('@/models/widgets/tables/TimeJournalTable/drilling'),
        label: 'Time Journal',
        icon: TABLE_ICON,
    },
    [Widget.CompletionsTimeJournalTable]: {
        import: () => import('@/models/widgets/tables/TimeJournalTable/completions'),
        label: 'Time Journal',
        icon: TABLE_ICON,
    },
    [Widget.SlideStatTable]: {
        import: () => import('@/models/widgets/tables/SlideStatTable'),
        label: 'Slide Statistics',
        icon: TABLE_ICON,
    },
    [Widget.SurveyTable]: {
        import: () => import('@/models/widgets/tables/SurveyPointsTables/SurveyTable'),
        label: 'Surveyed Trajectory',
        icon: TABLE_ICON,
    },
    [Widget.TrippStatTable]: {
        import: () => import('@/models/widgets/tables/TrippStatTable'),
        label: 'Tripping Statistics',
        icon: TABLE_ICON,
    },
    [Widget.WellRunSheetTable]: {
        import: () => import('@/models/widgets/tables/WellRunSheetTable'),
        label: 'Well Run Sheet',
        icon: TABLE_ICON,
    },
    [Widget.MudReportsTable]: {
        import: () => import('@/models/widgets/tables/MudReportsTable'),
        label: 'Mud Reports',
        icon: TABLE_ICON,
    },
    [Widget.MudProgramTable]: {
        import: () => import('@/models/widgets/tables/MudProgramTable'),
        label: 'Mud Program',
        icon: TABLE_ICON,
    },
    [Widget.PlannedTrajectoriesTable]: {
        import: () => import('@/models/widgets/tables/PlannedTrajectoriesTable'),
        label: 'Planned Trajectories',
        icon: TABLE_ICON,
    },

    [Widget.IntervalSummaryTable]: {
        import: () => import('@/models/widgets/tables/IntervalSummaryTable'),
        label: 'Stage Depth Summary',
        icon: TABLE_ICON,
    },

    [Widget.DrillingTroubleSummaryTable]: {
        import: () => import('@/models/widgets/tables/TroubleSummaryTable/drilling'),
        label: 'Trouble Summary',
        icon: TABLE_ICON,
    },
    [Widget.CompletionsTroubleSummaryTable]: {
        import: () => import('@/models/widgets/tables/TroubleSummaryTable/completions'),
        label: 'Trouble Summary',
        icon: TABLE_ICON,
    },

    [Widget.DrillingRecommendationSummaryTable]: {
        import: () => import('@/models/widgets/tables/RecommendationSummaryTable/drilling'),
        label: 'Recommendations',
        icon: TABLE_ICON,
    },
    [Widget.CompletionsRecommendationSummaryTable]: {
        import: () => import('@/models/widgets/tables/RecommendationSummaryTable/completions'),
        label: 'Recommendations',
        icon: TABLE_ICON,
    },

    //charts
    [Widget.AzimuthAnalysisChart]: {
        import: () => import('@/models/widgets/charts/AzimuthAnalysisChart'),
        label: 'Azimuth Analysis',
        icon: CHART_ICON,
    },
    [Widget.ConnectionsTimeChart]: {
        import: () => import('@/models/widgets/charts/ConnectionsTimeChart'),
        label: 'Weight to Weight Time',
        icon: CHART_ICON,
    },
    [Widget.ConnectionsDistributionChart]: {
        import: () => import('@/models/widgets/charts/ConnectionsDistributionChart'),
        label: 'Weight to Weight Distribution',
        icon: CHART_ICON,
    },
    [Widget.CumulativeTortuosityChart]: {
        import: () => import('@/models/widgets/charts/CumulativeTortuosityChart'),
        label: 'Cumulative DLS Tortuosity',
        icon: CHART_ICON,
    },
    [Widget.DaysVsDepthSingleWellChart]: {
        import: () => import('@/models/widgets/charts/DaysVsDepth/singlewell'),
        label: 'Days vs Depth',
        icon: CHART_ICON,
    },
    [Widget.CostVsDepthChart]: {
        import: () => import('@/models/widgets/charts/CostVsDepthChart'),
        label: 'Cost vs Depth',
        icon: CHART_ICON,
    },
    [Widget.DlsVarianceChart]: {
        import: () => import('@/models/widgets/charts/DlsVarianceChart'),
        label: 'DLS Variance',
        icon: CHART_ICON,
    },
    [Widget.DrillingActivitiesByDayChart]: {
        import: () => import('@/models/widgets/charts/DrillingActivitiesByDayChart'),
        label: 'Drilling Activities By Day',
        icon: CHART_ICON,
    },
    [Widget.DrillingActivitiesTotalChart]: {
        import: () => import('@/models/widgets/charts/DrillingActivitiesTotalChart'),
        label: 'Drilling Activities Total',
        icon: CHART_ICON,
    },
    [Widget.DrillingDistanceChart]: {
        import: () => import('@/models/widgets/charts/DrillingDistanceChart'),
        label: 'Drilling Distance',
        icon: CHART_ICON,
    },
    [Widget.DrillingROPChart]: {
        import: () => import('@/models/widgets/charts/DrillingROPChart'),
        label: 'Drilling ROP',
        icon: CHART_ICON,
    },
    [Widget.DrillingTimeChart]: {
        import: () => import('@/models/widgets/charts/DrillingTimeChart'),
        label: 'Drilling Time',
        icon: CHART_ICON,
    },
    [Widget.InclinationAnalysisChart]: {
        import: () => import('@/models/widgets/charts/InclinationAnalysisChart'),
        label: 'Inclination Analysis',
        icon: CHART_ICON,
    },
    [Widget.PlanViewChart]: {
        import: () => import('@/models/widgets/charts/pva/PlanViewChart/drilling'),
        label: '2D Plan View',
        icon: CHART_ICON,
    },
    [Widget.CompletionsPlanViewChart]: {
        import: () => import('@/models/widgets/charts/pva/PlanViewChart/completions'),
        label: '2D Plan View',
        icon: CHART_ICON,
    },
    [Widget.ProximityToPlanChart]: {
        import: () => import('@/models/widgets/charts/ProximityToPlanChart'),
        label: 'Ladder Plot',
        icon: CHART_ICON,
    },
    [Widget.Pva3dChart]: {
        import: () => import('@/models/widgets/charts/pva/Pva3dChart/drilling'),
        label: '3D Plan vs Actual',
        icon: CHART_ICON,
    },
    [Widget.CompletionsPva3dChart]: {
        import: () => import('@/models/widgets/charts/pva/Pva3dChart/completions'),
        label: '3D Plan vs Actual',
        icon: CHART_ICON,
    },
    [Widget.RotaryBuildwalkChart]: {
        import: () => import('@/models/widgets/charts/RotaryBuildwalkChart'),
        label: 'Rotary Build/Walk',
        icon: CHART_ICON,
    },
    [Widget.RotaryDrillingChart]: {
        import: () => import('@/models/widgets/charts/RotaryDrillingChart'),
        label: 'Rotary Drilling',
        icon: CHART_ICON,
    },
    [Widget.RotaryTendencyChart]: {
        import: () => import('@/models/widgets/charts/RotaryTendencyChart'),
        label: 'Rotary Tendency',
        icon: CHART_ICON,
    },
    [Widget.SeparationFromPlanChart]: {
        import: () => import('@/models/widgets/charts/SeparationFromPlanChart'),
        label: 'Travelling Cylinder',
        icon: CHART_ICON,
    },
    [Widget.SlideDrillingChart]: {
        import: () => import('@/models/widgets/charts/SlideDrillingChart'),
        label: 'Slide Drilling',
        icon: CHART_ICON,
    },
    [Widget.TvdAnalysisChart]: {
        import: () => import('@/models/widgets/charts/TvdAnalysisChart'),
        label: 'TVD Analysis',
        icon: CHART_ICON,
    },
    [Widget.VerticalSectionChart]: {
        import: () => import('@/models/widgets/charts/pva/VerticalSectionChart/drilling'),
        label: '2D Vertical Section',
        icon: CHART_ICON,
    },
    [Widget.CompletionsVerticalSectionChart]: {
        import: () => import('@/models/widgets/charts/pva/VerticalSectionChart/completions'),
        label: '2D Vertical Section',
        icon: CHART_ICON,
    },
    [Widget.ToolFaceChart]: {
        import: () => import('@/models/widgets/charts/ToolFaceChart'),
        label: 'Toolface',
        icon: 'mdi-bullseye'
    },
    [Widget.TrippingConnectionsAnalysisChart]: {
        import: () => import('@/models/widgets/charts/TrippingConnectionsAnalysisChart'),
        label: 'Tripping Connections Analysis',
        icon: CHART_ICON,
    },
    [Widget.TrippingDistanceChart]: {
        import: () => import('@/models/widgets/charts/TrippingDistanceChart'),
        label: 'Tripping Distance',
        icon: CHART_ICON,
    },
    [Widget.TrippingSpeedAnalysisChart]: {
        import: () => import('@/models/widgets/charts/TrippingSpeedAnalysisChart'),
        label: 'Tripping Speed Analysis',
        icon: CHART_ICON,
    },
    [Widget.TrippingSpeedChart]: {
        import: () => import('@/models/widgets/charts/TrippingSpeedChart'),
        label: 'Tripping Speed',
        icon: CHART_ICON,
    },
    [Widget.TrippingTimeChart]: {
        import: () => import('@/models/widgets/charts/TrippingTimeChart'),
        label: 'Tripping Time',
        icon: CHART_ICON,
    },
    [Widget.ZeroWobChart]: {
        import: () => import('@/models/widgets/charts/ZeroWobChart'),
        label: 'Zero WOB',
        icon: CHART_ICON,
    },
    [Widget.ZeroDiffChart]: {
        import: () => import('@/models/widgets/charts/ZeroDiffChart'),
        label: 'Zero DiffP',
        icon: CHART_ICON,
    },
    [Widget.ScatterPlot]: {
        import: () => import('@/models/widgets/charts/ScatterPlot'),
        label: 'Scatter Plot',
        icon: 'mdi-scatter-plot-outline'
    },
    [Widget.RotaryProjectionsChart]: {
        import: () => import('@/models/widgets/charts/RotaryProjections'),
        label: 'Rotary Projections',
        icon: 'mdi-chart-multiline'
    },
    [Widget.SchematicDesignChart]: {
        import: () => import('@/models/widgets/charts/SchematicDesignChart'),
        label: 'Wellbore Schematic',
        icon: 'mdi-vector-link',
    },
    [Widget.DesignDetailsView]: {
        import: () => import('@/models/widgets/charts/DesignDetailsView'),
        label: 'Wellbore Design',
        icon: CHART_ICON,
    },

    //multi well charts
    [Widget.ReportChart]: {
        import: () => import('@/models/widgets/charts/distributionReports/ReportChart'),
        label: 'Distribution Report',
        icon: 'mdi-chart-bar-stacked',
    },
    [Widget.RunDistributionReport]: {
        import: () => import('@/models/widgets/charts/distributionReports/DistributionReport/drilling'),
        label: 'Run Distribution Report',
        icon: 'mdi-chart-waterfall',
    },
    [Widget.StageDistributionReport]: {
        import: () => import('@/models/widgets/charts/distributionReports/DistributionReport/completions'),
        label: 'Stage Distribution Report',
        icon: 'mdi-chart-waterfall',
    },
    [Widget.MultiStatisticReport]: {
        import: () => import('@/models/widgets/charts/distributionReports/MultiStatisticReport'),
        label: 'Custom Distribution Report',
        icon: 'mdi-chart-multiple',
    },
    [Widget.DaysVsDepthMultiWellChart]: {
        import: () => import('@/models/widgets/charts/DaysVsDepth/multiwell'),
        label: 'Days vs Depth',
        icon: 'mdi-chart-sankey',
    },
    [Widget.WellsMap]: {
        import: () => import('@/models/widgets/ol/WellsMap'),
        label: 'Map Widget',
        icon: 'mdi-map-outline',
    },
    [Widget.WellMapWidthEdm]: {
        import: () => import('@/models/widgets/ol/WellMapWidthEdm/drilling'),
        label: 'Map View',
        icon: 'mdi-map-outline',
    },
    [Widget.CompletionsWellMapWidthEdm]: {
        import: () => import('@/models/widgets/ol/WellMapWidthEdm/completions'),
        label: 'Map View',
        icon: 'mdi-map-outline',
    },

    //multi well tables
    [Widget.BiRunSummary]: {
        import: () => import('@/models/widgets/tables/BiSummaryTable/drilling'),
        label: 'Run Summary',
        icon: TABLE_ICON,
    },
    [Widget.BiStageSummary]: {
        import: () => import('@/models/widgets/tables/BiSummaryTable/completions'),
        label: 'Stage Summary',
        icon: TABLE_ICON,
    },
    [Widget.MultiWellDrillingTroubleSummaryTable]: {
        import: () => import('@/models/widgets/tables/BiTroubleSummaryTable/drilling'),
        label: 'Trouble Summary',
        icon: 'mdi-calendar-alert'
    },
    [Widget.MultiWellCompletionsTroubleSummaryTable]: {
        import: () => import('@/models/widgets/tables/BiTroubleSummaryTable/completions'),
        label: 'Trouble Summary',
        icon: 'mdi-calendar-alert'
    },
    [Widget.MultiWellDrillingRecommendationSummaryTable]: {
        import: () => import('@/models/widgets/tables/BiRecommendationSummaryTable/drilling'),
        label: 'Recommendation Summary',
        icon: 'mdi-calendar-star'
    },
    [Widget.MultiWellCompletionsRecommendationSummaryTable]: {
        import: () => import('@/models/widgets/tables/BiRecommendationSummaryTable/completions'),
        label: 'Recommendation Summary',
        icon: 'mdi-calendar-star'
    },

    //logs
    [Widget.DrillingControlLog]: {
        import: () => import('@/models/widgets/logs/ControlLog/drilling'),
        label: 'Drilling Control Log'
    },
    [Widget.CompletionsControlLog]: {
        import: () => import('@/models/widgets/logs/ControlLog/completions'),
        label: 'Completions Control Log'
    },
    [Widget.DrillingCustomLog]: {
        import: () => import('@/models/widgets/logs/CustomLog/drilling'),
        label: 'Custom Well Log',
        icon: 'mdi-chart-timeline-variant-shimmer'
    },
    [Widget.CompletionsCustomLog]: {
        import: () => import('@/models/widgets/logs/CustomLog/completions'),
        label: 'Custom Well Log',
        icon: 'mdi-chart-timeline-variant-shimmer'
    },
    [Widget.CompletionsCustomLogWidget]: {
        import: () => import('@/models/widgets/logs/CustomLog/completions/widget'),
        label: 'Custom Well Log',
        icon: 'mdi-chart-timeline-variant-shimmer'
    },
    [Widget.CompletionsCustomLogWidgetProjectIntelligence]: {
        import: () => import('@/models/widgets/logs/CustomLog/completions/CompletionsCustomLogProjectIntelligenceModel'),
        label: 'Completions Custom Well Log',
        icon: 'mdi-chart-timeline-variant-shimmer'
    },
    [Widget.DrillingCustomLogWidget]: {
        import: () => import('@/models/widgets/logs/CustomLog/drilling/widget'),
        label: 'Custom Well Log',
        icon: 'mdi-chart-timeline-variant-shimmer'
    },
    [Widget.DrillingCustomLogWidgetProjectIntelligence]: {
        import: () => import('@/models/widgets/logs/CustomLog/drilling/DrillingCustomWellLogProjectIntelligenceModel'),
        label: 'Drilling Custom Well Log',
        icon: 'mdi-chart-timeline-variant-shimmer'
    },
    [Widget.DepthComparisonLog]: {
        import: () => import('@/models/widgets/logs/DepthAnalysisLog'),
        label: 'Depth Analysis Log',
        icon: 'mdi-compare',
    },
    [Widget.DepthComparisonLogWidget]: {
        import: () => import('@/models/widgets/logs/DepthAnalysisLogWidget'),
        label: 'Depth Analysis Log',
        icon: 'mdi-compare'
    },
    [Widget.TimeAnalysisLog]: {
        import: () => import('@/models/widgets/logs/TimeAnalysisLog/drilling'),
        label: 'Time Analysis Log',
        icon: 'mdi-chart-line',
    },
    [Widget.DrillingTimeAnalysisLogWidget]: {
        import: () => import('@/models/widgets/logs/TimeAnalysisLog/drilling/widget'),
        label: 'Time Analysis Log',
        icon: 'mdi-chart-line',
    },
    [Widget.CompletionsTreatmentPlot]: {
        import: () => import('@/models/widgets/logs/TimeAnalysisLog/completions'),
        label: 'Treatment Time Log',
        icon: 'mdi-chart-line',
    },
    [Widget.CompletionsTreatmentPlotWidget]: {
        import: () => import('@/models/widgets/logs/TimeAnalysisLog/completions/widget'),
        label: 'Treatment Time Log',
        icon: 'mdi-chart-line',
    },
    [Widget.TrippingLog]: {
        import: () => import('@/models/widgets/charts/TrippingLog'),
        label: 'Field Engineering',
        icon: 'mdi-chart-scatter-plot'
    },

    //gauges
    [Widget.ChannelSolidGauge]: {
        import: () => import('@/models/widgets/gauges/ChannelSolidGauge'),
        label: 'Solid Gauge',
        icon: 'mdi-gauge'
    },
    [Widget.StatisticsHub]: {
        import: () => import('@/models/widgets/gauges/StatisticsHub'),
        label: 'Section Summary',
        icon: WIDGET_ICON,
    },

    //frames
    [Widget.DataGrid]: {
        import: () => import('@/models/widgets/frames/DataGrid'),
        label: 'Data Grid',
        icon: 'mdi-file-table-box-outline'
    },
    [Widget.MultiTabs]: {
        import: () => import('@/models/widgets/frames/MultiTabs/SingleWell/drilling'),
        label: 'Multi-Tab Widget',
        icon: 'mdi-file-table-box-multiple-outline'
    },
    [Widget.SWCMultiTabs]: {
        import: () => import('@/models/widgets/frames/MultiTabs/SingleWell/completions'),
        label: 'Multi-Tab Widget',
        icon: 'mdi-file-table-box-multiple-outline'
    },
    [Widget.MWDMultiTabs]: {
        import: () => import('@/models/widgets/frames/MultiTabs/MultiWell/drilling'),
        label: 'Multi-Tab Widget',
        icon: 'mdi-file-table-box-multiple-outline'
    },
    [Widget.MWCMultiTabs]: {
        import: () => import('@/models/widgets/frames/MultiTabs/MultiWell/completions'),
        label: 'Multi-Tab Widget',
        icon: 'mdi-file-table-box-multiple-outline'
    },
    [Widget.SingleWellWidgetWrapper]: {
        import: () => import('@/models/widgets/frames/SingleWellWidgetWrapper'),
        label: 'Single Well Widget Wrapper',
    },
    [Widget.WidgetsLayout]: {
        import: () => import('@/models/widgets/frames/WidgetsLayout/SingleWell'),
        label: 'Widgets Layout'
    },
    [Widget.MultiWellDrillingWidgetsLayout]: {
        import: () => import('@/models/widgets/frames/WidgetsLayout/MultiWell/drilling'),
        label: 'Drilling Widgets Layout'
    },
    [Widget.ProjectWidgetsLayout]: {
        import: () => import('@/models/widgets/frames/WidgetsLayout/MultiWell/project'),
        label: 'Project Widgets Layout'
    },
    [Widget.MultiWellCompletionsWidgetsLayout]: {
        import: () => import('@/models/widgets/frames/WidgetsLayout/MultiWell/completions'),
        label: 'Completions Widgets Layout'
    },
    [Widget.CompletionsWidgetsLayout]: {
        import: () => import('@/models/widgets/frames/WidgetsLayout/Completions'),
        label: 'Widgets Layout'
    },
    [Widget.Geosteering]: {
        import: () => import('@/models/widgets/charts/pva/Geosteering'),
        label: 'Geosteering',
        icon: 'mdi-chart-line-stacked',
    },
    [Widget.DrillingGeosteeringWidget]: {
        import: () => import('@/models/widgets/charts/pva/Geosteering/drilling/DrillingGeosteeringWidget'),
        label: 'Geosteering',
        icon: 'mdi-chart-line-stacked',
    },
    [Widget.CompletionsGeosteeringWidget]: {
        import: () => import('@/models/widgets/charts/pva/Geosteering/completions/CompletionsGeosteeringWidget'),
        label: 'Geosteering',
        icon: 'mdi-chart-line-stacked',
    },
    [Widget.RoadmapConfiguration]: {
        import: () => import('@/models/widgets/frames/RoadmapConfiguration'),
        label: 'Roadmap Configuration'
    },

    //admin tables
    [Widget.CorrectionsTable]: {
        import: () => import('@/models/widgets/admin/tables/CorrectionsTable'),
        label: 'Corrections'
    },
    [Widget.ManualActivitiesTable]: {
        import: () => import('@/models/widgets/admin/tables/ManualActivitiesTable'),
        label: 'Manual Activities'
    },
    [Widget.StandCorrectionsTable]: {
        import: () => import('@/models/widgets/admin/tables/StandCorrectionsTable'),
        label: 'Stand Corrections'
    },
    [Widget.DrillingZonesTable]: {
        import: () => import('@/models/widgets/admin/tables/ZonesTable/drilling'),
        label: 'Zones'
    },
    [Widget.CompletionZonesTable]: {
        import: () => import('@/models/widgets/admin/tables/ZonesTable/completion'),
        label: 'Zones'
    },
    [Widget.WellsAuditTable]: {
        import: () => import('@/models/widgets/admin/tables/WellsAuditTable'),
        label: 'Wells Audit'
    },
    [Widget.EventCorrectionsTable]: {
        import: () => import('@/models/widgets/admin/tables/EventCorrectionsTable'),
        label: 'Event Corrections'
    },
    [Widget.ScenarioEditor]: {
        import: () => import('@/models/widgets/editors/ScenarioEditor'),
        label: 'Engineering Scenario',
        icon: 'mdi-newspaper-variant-multiple-outline'
    },
    [Widget.SurveyNodeWidget]: {
        import: () => import('@/models/widgets/edm/SurveyNode'),
        label: 'Survey',
    },
    [Widget.EdmPlanViewChart]: {
        import: () => import('@/models/widgets/edm/PlanViewChart'),
        label: 'Plan View',
        icon: 'mdi-flip-to-front'
    },
    [Widget.EdmVerticalSectionChart]: {
        import: () => import('@/models/widgets/edm/VerticalSectionChart'),
        label: 'Vertical Section',
        icon: 'mdi-select-compare'
    },
    [Widget.EdmPva3dChart]: {
        import: () => import('@/models/widgets/edm/Pva3dChart'),
        label: '3D View',
        icon: 'mdi-cube-outline'
    },
    [Widget.DrillingProgram]: {
        import: () => import('@/models/widgets/editors/DrillingProgram'),
        label: 'Drilling Program',
        icon: 'mdi-newspapernotebook-outline'
    },
    [Widget.EdmAcPerWellTable]: {
        import: () => import('@/models/widgets/edm/AcPerWellTable'),
        label: 'AC per Well',
        icon: TABLE_ICON,
    },
    [Widget.EdmAcSummaryTable]: {
        import: () => import('@/models/widgets/edm/AcSummaryTable'),
        label: 'AC Summary',
        icon: TABLE_ICON,
    },

    //options
    [Widget.Notes]: {
        import: () => import('@/models/widgets/options/Notes'),
        label: 'Template Notes',
        icon: 'mdi-note-text-outline',
    },
}
