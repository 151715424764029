import Crud from '@/store/modules/crud.js'

const URL = 'admin/interpretation_provider'

const interpretationProviders = new Crud(URL)

interpretationProviders.getters = () => ({
    getInterpretationProviders: state => state.data,
})

const actions = interpretationProviders.actions()
interpretationProviders.actions = () => ({
    ...actions,
    UPDATE: ({commit}, params) => {
        commit('CHANGE_STATUS', {name: 'processing', message: 'Updating'})
        return interpretationProviders._apiCall({commit}, {
            method: 'PUT',
            url: interpretationProviders.getUrl(params) + (params.id ? '/' + params.id : ''),
            params: interpretationProviders.addQueryParams(params.params),
            data: params.data,
            skipCommit: params.skipCommit,
        }, 'UPDATED')
    },
    CHECK: ({commit}, payload) => {
        return interpretationProviders._apiCall({commit}, {
            method: 'POST',
            url: URL + '/check',
            data: payload,
            skipCommit: true,
        })
    }
})

export default interpretationProviders.module()
