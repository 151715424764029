import {_apiCall} from "@/utils/api"
import {compileUrl} from "@/utils/functions.ts"

const defaultParams = {
    needSuccessNotification: true
}
const BASE_URL = '/geosteering'
const actions = {
    REQUEST_PROVIDER_INTERPRETATION_LIST: async (ctx, {well_id}) => _apiCall(ctx, {
        ...defaultParams,
        method: 'GET',
        url: await compileUrl(BASE_URL + '/${well_id}/interpretation/provider_data', {
            well_id,
        }),
    }).then(resp => resp.data),
    LINK_TO_INTERPRETATION: async (ctx, {well_id, data}) => _apiCall(ctx, {
        ...defaultParams,
        method: 'POST',
        url: await compileUrl(BASE_URL + '/${well_id}/interpretation/link_to', {
            well_id,
        }),
        data,
    }).then(resp => resp.data),
    REMOVE_LINK_TO_INTERPRETATION: async (ctx, {well_id}) => _apiCall(ctx, {
        ...defaultParams,
        method: 'POST',
        url: await compileUrl(BASE_URL + '/${well_id}/interpretation/remove_link', {
            well_id,
        }),
    }).then(resp => resp.data),
    GET_WELL_INTERPRETATION: async (ctx, {well_id}) => _apiCall(ctx, {
        ...defaultParams,
        method: 'GET',
        url: await compileUrl(BASE_URL + '/${well_id}/interpretation', {
            well_id,
        }),
    }).then(resp => resp.data),
    STORE_WELL_INTERPRETATION: async (ctx, {well_id, data}) => _apiCall(ctx, {
        ...defaultParams,
        method: 'PUT',
        url: await compileUrl(BASE_URL + '/${well_id}/interpretation', {
            well_id,
        }),
        data,
    }).then(resp => resp.data),
}

export default {
    namespaced: true,
    actions,
}

