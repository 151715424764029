import {ChartEntity} from "@/models/charts/BaseChart"
import moment from "moment"
import {DEFAULT_DATETIME_FORMAT} from "@/assets/constants.js"
import {simpleErrorNotification, simpleSuccessNotification} from "./helpers"
import {IRange} from "@/libs/uPlot/types"
import {Nullable} from "@/types/common"
import {hexToHsl, hslToHex, sanitizeHex} from "@super-effective/colorutils"
import {RowDataTransaction} from "ag-grid-community"
import {NotUndefined} from "vue"
import {AverageType} from "@/models/widgets/logs/constants"
import {AiWell, GetWellAlignedPeriodArgs, RunMarkerInfo, WellInfo, WellPeriod} from "@/core/types/ai-interfaces"
import {EVENT_MARKERS, PERIOD_SOURCE_TYPES, WELL_STATUSES} from "@/assets/enums"
import {camelCase, snakeCase} from "lodash"

const Parallel = require('paralleljs')

export const addAlpha = (color: string, opacity: Nullable<number>) => {
    if (color.replace(/[^a-zа-яё0-9\s]/gi, '').length === 8) {
        color = color.substring(0, color.length - 2)
    }
    opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
    const opacityPostfix = ('0' + opacity.toString(16).toUpperCase()).slice(-2)
    return color + opacityPostfix
}

export function applyShift(color: Nullable<string>, shift: Nullable<number>) {
    if (!color || !shift) return color
    shift /= 100
    const modifiedColor = hexToHsl(sanitizeHex(color))
    modifiedColor.lightness = Math.min(1, Math.max(0, modifiedColor.lightness + shift))
    return hslToHex(modifiedColor.hue, modifiedColor.saturation, modifiedColor.lightness)
}

export const mapKeys = <T>(
    data: Record<string, T>,
    keys: Record<string, string>
): Record<string, T> => {
    return _.mapKeys(data, (value, key) => keys[key] || key)
}

export const mergeChartEntity = (destination: Partial<ChartEntity>, ...args: Array<Partial<ChartEntity>>): Partial<ChartEntity> => {
    return _.merge(destination, ...args)
}

export function transposeData<T>(data: Array<T>, keys: Array<keyof T>): Record<keyof T, any> {
    //TODO: refact to improve performance
    return keys.reduce((acc, key) => ({
        ...acc,
        [key]: data.map(item => item[key] ?? null)
    }), {} as Record<keyof T, any>)
}

export function addUnit(str: string, unit: string | null | undefined): string {
    return unit ? `${str} (${_.escape(unit)})` : str
}

export interface Settable {
    set: (_value: any) => void
    get?: () => any
}

export const defineProperty = (thisObject: Object, propertyName: string, entity: Settable) => {
    Object.defineProperty(thisObject, propertyName, {
        enumerable: true,
        configurable: true,
        get: () => entity.get ? entity.get() : entity,
        set: filters => entity.set(filters)
    })
}

export const compileUrl = async (url: string, params: Record<string, any> = {}): Promise<string> => {
    const incorrect = [undefined, null, '']
    const incorrectKey = Object.keys(params).find(key => incorrect.includes(params[key]))
    if (incorrectKey) {
        console.error(`Url parameter is not defined: ${incorrectKey}`)
        return Promise.reject({message: null})
    }
    const compiled = _.template(url)
    return compiled(params)
}

export const timestampToString = (timestamp: number): string => {
    return moment(timestamp).utc(false).format(DEFAULT_DATETIME_FORMAT)
}
export const stringToTimestamp = (dateString: string): number | undefined => {
    const result = moment(dateString)
    return result.isValid() ? result.utc(true).valueOf() : undefined
}

type Getter<T> = () => T
export type TypeOrGetter<T> = Getter<T> | T

export const getOrEvaluate = <RType = any>(payload: TypeOrGetter<RType>) => _.isFunction(payload) ? payload() : payload

export const parseFraction = (payload: string): number => {
    const regExp = /([-+])?(\d+)?(\s+)?(\d+)?(\/)?(\d+)?/
    const parsedNumber = payload.match(regExp)
    if (!parsedNumber || parsedNumber[5] !== '/') {
        const parsed = parseFloat(payload)
        if (!Number.isFinite(parsed)) return NaN
        return parsed
    }
    let integer = 0
    let nominator
    if (parsedNumber[4] != null) {
        integer = +parsedNumber[2]
        nominator = +parsedNumber[4]
    } else {
        nominator = +parsedNumber[2]
    }
    if (isNaN(integer)) integer = 0
    const denominator = +parsedNumber[6]
    const result = integer + nominator / denominator
    return Number.isFinite(result) ? result : NaN
}

/**
 * Default compare function
 * @param left first value
 * @param right second value
 */
export const defaultCompare = (left: any, right: any) => {
    if (left === undefined && right === undefined) {
        return 0
    }

    if (left === undefined) {
        return 1
    }

    if (right === undefined) {
        return -1
    }

    if (left < right) {
        return -1
    }

    if (left > right) {
        return 1
    }

    return 0
}

export const wrapInArray = <T extends any>(data: Array<T> | T) => Array.isArray(data) ? data : [data]

/**
 * Concatenate not empty arrays with separator
 * @param arrays array of arrays
 * @param separator item used to separate arrays
 */
export const concatNotEmptyArraysWithSeparator = (arrays: Array<Array<unknown>>, separator: unknown = 'separator') => {
    return arrays.filter(item => item.length > 0).reduce((acc, item) => {
        return acc.length === 0 ? [...item] : [...acc, separator, ...item]
    }, [])
}

export const notificate = (promise: Promise<void | { message: string }>) => promise.then((resp) => {
    resp && simpleSuccessNotification(resp.message)
}).catch(err => {
    simpleErrorNotification(err.message)
})

export const objToArray = (obj: Record<string, Object>) => (Object.keys(obj) as Array<keyof typeof obj>).map((key: keyof typeof obj) => {
    return obj[key]
})

export function fitRange({zoom, range}: { zoom: IRange, range: IRange }) {
    let {min, max} = zoom
    if (min < range.min) {
        max += range.min - min
    }
    if (max > range.max) {
        min -= max - range.max
    }
    min = Math.max(min, range.min)
    max = Math.min(max, range.max)
    return {min, max}
}

export function smoothMedian({windowSize, data}: {windowSize: number, data: Array<[number, Nullable<number>]>}): Array<[number, Nullable<number>]> {
    if (windowSize === 0) {
        return data
    }
    const smoothData = []
    for (let i = 0; i < data.length; i++) {
        if (data[i][1] == null) {
            smoothData.push([data[i][0], null])
            continue
        }
        let medianArray = []
        for (let k = 0; k < i; k++) {
            const point = data[i - k]
            if (point[1] == null) continue
            if (Math.abs(data[i][0] - point[0]) > windowSize / 2) break
            medianArray.push(point[1])
        }
        for (let k = 1; k < data.length - i - 1; k++) {
            const point = data[i + k]
            if (point[1] == null) continue
            if (Math.abs(data[i][0] - point[0]) > windowSize / 2) break
            medianArray.push(point[1])
        }
        medianArray = quickSort(medianArray, 0, medianArray.length - 1)
        const y = medianArray[Math.floor(medianArray.length / 2)]
        const point = [data[i][0], y]
        smoothData.push(point)
    }
    return smoothData as Array<[number, Nullable<number>]>
}

export function smoothAverage({windowSize, data}: {windowSize: number, data: Array<[number, Nullable<number>]>}): Array<[number, Nullable<number>]> {
    function getDataInWindow(start: number, end: number) {
        const medianArray = []
        const increment = start < end ? 1 : -1
        let k = start

        while (k !== end) {
            k += increment
            const point = data[k]
            if (point[1] == null) continue
            if (Math.abs(data[start][0] - point[0]) > windowSize / 2) break
            medianArray.push(point[1])
        }
        return medianArray
    }

    if (windowSize === 0) {
        return data
    }
    const smoothData = []
    for (let i = 0; i < data.length; i++) {
        const currentY = data[i][1]
        if (currentY == null) {
            smoothData.push([data[i][0], null])
            continue
        }
        const medianArray = [
            currentY,
            ...getDataInWindow(i, 0),
            ...getDataInWindow(i, data.length - 1)
        ]
        const y = medianArray.reduce((a, b) => a + b, 0) / medianArray.length
        const point: [number, Nullable<number>] = [data[i][0], y]
        smoothData.push(point)
    }
    return smoothData as Array<[number, Nullable<number>]>
}

// https://www.guru99.com/quicksort-in-javascript.html

export function quickSort(items: Array<number>, left: number, right: number) {
    function swap(items: Array<unknown>, leftIndex: number, rightIndex: number) {
        const temp = items[leftIndex]
        items[leftIndex] = items[rightIndex]
        items[rightIndex] = temp
    }

    function partition(items: Array<number>, left: number, right: number) {
        const pivot = items[Math.floor((right + left) / 2)] //middle element
        let i = left, //left pointer
            j = right //right pointer
        while (i <= j) {
            while (items[i] < pivot) {
                i++
            }
            while (items[j] > pivot) {
                j--
            }
            if (i <= j) {
                swap(items, i, j) //sawpping two elements
                i++
                j--
            }
        }
        return i
    }

    let index
    if (items.length > 1) {
        index = partition(items, left, right) //index returned from partition
        if (left < index - 1) { //more elements on the left side of the pivot
            quickSort(items, left, index - 1)
        }
        if (index < right) { //more elements on the right side of the pivot
            quickSort(items, index, right)
        }
    }
    return items
}

export function replaceArray(prev: unknown, next: unknown) {
    if (Array.isArray(prev)) {
        return next
    }
}

export function concatArray(prev: unknown, next: unknown) {
    if (Array.isArray(prev)) {
        return prev.concat(next)
    }
}

export function concatArrayWith(fn: (array: Array<unknown>) => Array<unknown>, prev: unknown, next: unknown) {
    const result = concatArray(prev, next)
    return result ? fn(result) : result
}

export function getOrEvaluateWithPayload(func: CallableFunction | unknown, payload: unknown) {
    return (typeof func === 'function' && payload != null) ? func(payload) : func
}

export async function getOrEvaluatePromiseWithPayload(func: CallableFunction | unknown, payload: unknown) {
    return (typeof func === 'function' && payload != null) ? await Promise.resolve(func(payload)) : func
}

export function notFalsy<T>(value: T | null | undefined): value is T {
    return !!value
}

export function sortAsNumbers(a: number, b: number) {
    return a - b
}

export const prepareTransaction: (newData: any, oldData: any, key?: string) => RowDataTransaction = (newData = [], oldData = [], key = 'row_key') => {
    const update: any[] = []
    const add: any[] = []
    const remove: any[] = []

    const currentRowIds: string[] = oldData.map((row: any) => row[key])

    const newRowIds: string[] = newData.map((row: any) => row[key])
    newData.forEach((row: any) => {
        if (currentRowIds.includes(row[key])) {
            update.push(row)
        } else {
            add.push(row)
        }
    })
    currentRowIds.forEach(id => {
        if (!newRowIds.includes(id)) {
            remove.push({[key]: id})
        }
    })
    return {update, add, remove}
}

export function assignExisted(destination: object, source: object) {
    const sourceKeys = Object.keys(source)
    const destinationKeys = Object.keys(destination)
    sourceKeys.forEach((key: string) => {
        if (destinationKeys.includes(key)) {
            destination[key as keyof object] = source[key as keyof object]
        }
    })
}

export function assignEmpty(destination: object, ...sources: Array<object>) {
    sources.forEach(source => {
        const undefinedPropertiesKeys = Object.keys(source)
            .filter(key => destination[key as keyof object] == null)
        assignExisted(destination, _.pick(source, undefinedPropertiesKeys))
    })
}

export function lastDefined<T>(defined: NotUndefined<T>, ...args: Array<T>): NotUndefined<T> {
    for (let i = args.length - 1; i >= 0; i--) {
        const value = args[i] as NotUndefined<T>
        if (value !== undefined) {
            return value
        }
    }
    return defined
}

export function assignArray<T>(destination: Array<T>, source: Array<T> = []) {
    destination.splice(0, destination.length, ...source)
    return destination
}

export function collapseProperties(params: Record<string, any>) {
    return _.mapValues(params, v => typeof v === 'function' ? v() : v)
}

export function processUrl(url: string, params: Record<string, any>) {
    const compiled = _.template(url)
    return compiled(collapseProperties(params)).replace(/\/{2,}/g, '/').replace(/\/$/, '')
}

export function emptyValueInterceptor<T>(value: T | string) {
    return value !== '' && value != null ? value : null
}

export async function smoothDataParallel(smoothType: AverageType, windowSize: number, data: Array<[number, Nullable<number>]>) {
    const process = new Parallel({windowSize, data}).require(quickSort)
    switch (smoothType) {
        case AverageType.SIMPLE:
            return await process.spawn(smoothAverage)
        case AverageType.MEDIAN:
            return await process.spawn(smoothMedian)
        default:
            return null
    }
}

export function clatch(from: number, to: number, value: number) {
    const [min, max] = to > from ? [from, to] : [to, from]
    return Math.max(min,
        Math.min(
            max,
            value,
        )
    )
}

interface CalcLookAheadArgs {
    value: number
    period: WellPeriod
}

export function calcLookAhead({value, period}: CalcLookAheadArgs) {
    if (period.autoUpdate) {
        return value
    }
    const periodType = _.get(period, 'source.type')
    if ([PERIOD_SOURCE_TYPES.buttonWell, PERIOD_SOURCE_TYPES.buttonToday].includes(periodType)) {
        return value
    }
    return 0
}

function deepKeysModify(obj: object, iterator: (s: string) => string): object {
    if (Array.isArray(obj)) {
        return obj.map(v => deepKeysModify(v, iterator))
    } else if (obj != null && _.isObject(obj)) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                //@ts-ignore
                [iterator(key)]: deepKeysModify(obj[key], iterator),
            }),
            {},
        )
    }
    return obj
}

export function snakeKeys(obj: object): object {
    return deepKeysModify(obj, snakeCase)
}

export function camelKeys(obj: object): object {
    return deepKeysModify(obj, camelCase)
}

export function getWellAlignedPeriod({period, well}: GetWellAlignedPeriodArgs): WellPeriod {
    if (!period || !well?.id) {
        return {
            source: {
                type: PERIOD_SOURCE_TYPES.buttonWell,
            }
        }
    }
    const start_date = period.start_date ?? well.start_date
    const end_date = period.end_date ?? well.end_date
    const start_depth = period.start_depth ?? well.start_depth
    const end_depth = period.end_depth ?? well.end_depth
    return {
        autoUpdate: well.status_name === WELL_STATUSES.active.name,
        ...period,
        start_date,
        start_depth,
        end_date,
        end_depth,
    }
}

function wrapRunPlotLine(info: RunMarkerInfo) {
    return {
        color: EVENT_MARKERS.BHA.color,
        value: info.x,
        zIndex: 100,
        width: 1,
        dashStyle: "longDash",
        label: {
            text: info.label,
            rotation: -90,
            x: -4,
            y: 0,
            textAlign: 'right',
            // verticalAlign: 'bottom',
            style: {
                color: EVENT_MARKERS.BHA.color,
                zIndex: 100,
            }
        }
    }
}

export function getDvdRunsPlotLines(data: Array<{ value: number, label: string }>) {
    const result: Record<string, unknown>[] = data?.map(runInfo => wrapRunPlotLine({x: runInfo.value, label: runInfo.label})) || []
    return result
}

export function getRunsPlotLines(data: Array<{ name?: string, data: Array<RunMarkerInfo>, id?: string }>) {
    const result: Record<string, unknown>[] = []
    const markers = data.filter(item => (
        item.name === 'run_marker' ||
        item.id === 'run_marker' ||
        item.name === 'casing_run_marker' ||
        item.id === 'casing_run_marker'
    ))
    for (const marker of markers) {
        marker.data.forEach((item: RunMarkerInfo) => {
            result.push(wrapRunPlotLine({x: item.x, label: item.label}))
        })
    }
    return result
}

export function isWell(well: Nullable<WellInfo>): well is AiWell {
    return !!(well as AiWell)?.id
}
